.member {

  .container {
    margin-bottom: 88px;
  }

  .overview {
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
    gap: 6.4rem;
    padding: 0;

    @media (max-width: $breakpoint-medium) {
      display: flex;
      flex-direction: column;
    }

    .card {
      margin-bottom: 2rem;
      align-items: center;
      justify-content: center;
      max-width: 341px;

      @media (min-width: $breakpoint-medium) {
        margin-bottom: 0;
      }

      figure {
        position: relative;
        width: 100%;
        margin: 0;

        &.hex {
          margin-bottom: 1.6rem;
          max-width: 228px;
        }

        img {
          width: 100%;

        }

        .bio {
          position: absolute;
          bottom: 0;
          color: white;
          padding: 0.8rem;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 10);

          .contact {
            margin-top: 0.5rem;

            img {
              width: 35px;
              height: 41px;
              margin-right: 1.3rem;
            }
          }

          h3 {
            color: white;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .more-info {
    background-color: #f8f8f8;

    .container {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }

    .offset-lg-1 {
      margin: 0;
    }

    ul {
      padding: 0 2.4rem 0 7rem;
      margin-bottom: 0;
    }

    li {
      display: flex;
      align-items: center;
      height: 4rem;
      position: relative;
    }

    li:before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-position-y: center;
      width: 30px;
      height: 30px;
      left: -40px;
      background-size: 16px 16px;
    }

    li:first-child:before {
      background-image: url("/theme/vrijopnaam/svg/cooperatie/calendar.svg");
    }

    li:nth-child(2):before {
      background-image: url("/theme/vrijopnaam/svg/cooperatie/pencil.svg");
    }

    li:not(:first-child):not(:nth-child(2)):before {
      background-image: url("/theme/vrijopnaam/svg/cooperatie/bullet.svg");
      background-size: 40px 40px;
      left: -50px;
    }
  }


  section {

    &.bg--blue {

      .container {
        padding: 4rem;
      }
    }

    &.img-right {

      .col-xl-6.d-sm-none {
        @media (max-width: 1430px) {
          margin-top: 6rem;
        }
      }

      .bg--orange {
        width: 340px;
        height: 340px;
        z-index: -100;
        border-radius: 8px;
      }

      img {
        position: absolute;
        height: 210px;
        width: 350px;
        right: 7rem;
        border-radius: 8px;
        top: 6.5rem;
      }
    }
  }
}

