.bg--orange {
    background-color: $kleur-orange;

    h2 {
        color: rgb(57, 60, 63);
        text-transform: uppercase;
    }

}

.bg--blue {
    background-color: $kleur-blue;

    h2 {
        color: rgb(57, 60, 63);
        text-transform: uppercase;
    }
}

.bg--gray {
    background-color: $kleur-grey-dark;

    h2 {
        color: #FFF !important;
        text-transform: uppercase;
    }

    h3 {
        color: #FFF !important;
    }

    p {
        color: #FFF !important;
    }
}

.bg-hexagon {
    background-image: url(/theme/vrijopnaam/svg/hexagon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-bottom: 16px;

    width: 288px;
    height: 333px;
    text-align: center;

    @media (min-width: $breakpoint-medium) {
        width: 266px;
        height: 308px;
        padding-top: 4rem;
    }

    @media (min-width: $breakpoint-large) {
        width: 248px;
        height: 283px;
        padding-top: 4rem;
    }

    @media (min-width: $breakpoint-xlarge) {
        width: 339px;
        height: 393px;
        padding-top: 4rem;
    }

    p:first-of-type {
        height: 7em;
        min-height: 66px;
    }
}

.bg--met-zon {
    //border: 1px dashed crimson;

    background-image: url('/theme/vrijopnaam/images/wolk-vogels.png');
    background-position: 80% 15%;
    background-repeat: no-repeat;
    padding: 9rem 0;

    & .col.d-flex {
        max-width: 100%;
        flex: unset;
        padding: 0;

        & .order-lg-last {
            margin-left: 10%;
        }

        & .col-lg-5 {
            flex: 0 0 50%;
            max-width: 702px !important;
        }
    }

    @media (max-width: $breakpoint-large) {
        background-position: 15% 85%;
        margin-top: 128px;
        padding: 4rem 0;
    }

    @media (max-width: $breakpoint-medium) {
        margin-top: 128px;
        background-position: 100% -15%;

        & .col.d-flex {
            flex-direction: column;

            & .order-lg-last {
                margin-left: 0;
                padding: 0;

                & p {
                    margin-bottom: 4rem;
                }
            }
        }
    }

    & img {
        width: 312px;
        margin-top: -15rem;
        margin-bottom: 2rem;
        max-width: unset;

        @media (max-width: $breakpoint-medium) {
            width: 288px;
        }
    }
}