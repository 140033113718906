.main-nav {
    background-color: $kleur-white;
    border-bottom-right-radius: 16px;
    min-height: 56px;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    border-bottom: 1px solid #eaeaea;

    @media (min-width: $breakpoint-medium) {
        min-height: 104px;
    }

    ul {
        position: relative;
    }

    .c-menu-list-container li {
        a.nav-link {
            padding: 1.3rem 0;
            @media (min-width: $breakpoint-medium) {
                margin-left: 1.6rem;
                padding: .7rem 0;
            }
            @media (min-width: $breakpoint-large) {
                margin-left: 2.4rem;
            }

            &.btn {
                margin: 1.6rem 0;
                display: inline-block;
                padding: 0.9rem 1.6rem;
                @media (min-width: $breakpoint-medium) {
                    font-size: 1.4rem;
                    line-height: 2rem;
                    white-space: nowrap;
                }
                @media (min-width: $breakpoint-large) {
                    font-size: 1.8rem;
                }
            }

            &.phone:hover {
                svg path {
                    fill: #327cac;
                }
            }
        }
    }

    .c-menu-list-container {
        @media (min-width: $breakpoint-medium) {
            border-bottom-right-radius: 16px;
            background-color: $kleur-white;
        }

        li {
            @media (max-width: 767px) {
                border-bottom: 1px $rgba-kleur-almost-black solid;
                margin-left: -16px;
                margin-right: -16px;
                padding-left: 16px;
                padding-right: 16px;
            }
            @media (min-width: $breakpoint-medium) {
                border-bottom: none;
            }
        }

        .navbar-brand {
            img {
                @media (min-width: $breakpoint-medium) {
                    position: relative;
                    top: -2px;
                }
                @media (min-width: $breakpoint-large) {
                    top: 1px;
                }
            }
        }
    }

    .navbar-toggler {
        padding: .8rem 1.5rem !important;
    }

    .phone-icon-container {
        a {
            line-height: 0;
            padding: .8rem !important;
        }
    }

    .show,
    .collapsing {
        display: flex;
        background-color: $kleur-orange;
        @media (min-width: $breakpoint-medium) {
            background-color: $kleur-white;
        }
    }
}


.main-nav--registration {
    min-height: 56px;

    img {
        height: 56px;
        width: 49px;
        @media (min-width: $breakpoint-medium) {
            height: 66px;
            width: 91px;
            position: relative;
            top: 8px;
        }
    }

    @media (min-width: $breakpoint-medium) {
        ul {
            height: 66px;
            align-items: center;
        }
        li {
            height: 100%;
            display: flex;

            a {
                align-self: center;
                cursor: pointer;
            }

            &:last-child {
                border-left: 2px solid $kleur-blue-light-2;
            }
        }
    }

    a {
        color: $kleur-almost-black;

        &:hover {
            text-decoration: none;
            color: #327cac;
        }
    }

    span {
        text-decoration: none;
        font-weight: 500;
    }

    .navbar-brand img {

    }

    .nav-link.phone:hover {
        svg {
            path {
                fill: $kleur-blue-wcag;
            }
        }
    }

    .nav-link.close-icon:hover {
        a {
            color: $kleur-blue-wcag;
        }

        svg {
            path {
                fill: $kleur-blue-wcag;
            }
        }
    }
}

.c-logo {
    width: 49px;
    height: 56px;
    z-index: 1;
    position: relative;
    top: 0.8rem;
    @media (min-width: $breakpoint-medium) {
        padding: 0;
        width: 77px;
        height: 88px;
        position: absolute;
        top: 17px;
        img {
            width: 77px;
            height: 88px;
        }
    }
    @media (min-width: $breakpoint-large) {
        padding: 0;
        width: 153px;
        height: 110px;
        top: 8px;
    }
}

.c-full-bleed-container {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background: #f8f8f8;
}

.c-primary-menu-list {
    background-color: $kleur-orange;
    font-size: 1.8rem;

    .navbar-brand {
        img {
            width: 77px;
            height: 88px;

            @media (min-width: $breakpoint-large) {
                width: 153px;
                height: 110px;
            }
        }
    }

    @media (min-width: $breakpoint-medium) {
        background-color: $kleur-white;
        height: 72px;
        border-bottom-right-radius: 16px;
        li.first.last {
            display: inline-block;
            margin-left: auto;
        }
    }

    li {
        font-size: 1.8rem;
        font-weight: $font-weight-medium;
        line-height: 3rem;
        @media (min-width: $breakpoint-medium) {
            font-size: 1.4rem;
            line-height: 2rem;
        }
        @media (min-width: $breakpoint-large) {
            font-size: 1.8rem;
        }
        @media (min-width: $breakpoint-xlarge) {
            font-size: 2rem;
        }
    }

    div {
        &:first-child {
            li:last-child {
                border-bottom: none;
            }
        }
    }
}

.c-secondary-menu-list {
    background-color: $kleur-grey-light-6;

    li {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: $font-weight-medium;
        @media (min-width: $breakpoint-medium) {
            font-size: 1.4rem;
            font-weight: $font-weight-light;
            line-height: 2rem;
            white-space: nowrap;
        }

        a {
            @media (min-width: $breakpoint-medium) {
                margin-left: 1.6rem;
            }
        }
    }
}

// Override Bootstrap CSS (Bug)
.navbar-expand-md {
    > .container {
        padding-right: 16px;
        padding-left: 16px;

        @media (min-width: $breakpoint-large) {
            padding-right: 24px;
            padding-left: 24px;
        }
    }
}

.navbar-scroll-down {
    animation: scrollDown 0.3s 1;
}

.navbar-scroll-up {
    animation: scrollUp 0.3s 1;
    position: fixed;
}

.navbar-scroll-down,
.navbar-scroll-up {
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
}

@keyframes scrollDown {
    0% {
        transform: translatey(-105%);
    }
    100% {
        transform: translatey(0%);
    }
}

@keyframes scrollUp {
    0% {
        transform: translatey(-100%);
    }
    100% {
        transform: translatey(0%);
    }
}

.pagination {
    .page-item:first-child .page-link {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
}

.btn_toggle-menu {
    display: block;
    position: relative;
    // right: 1rem;
    // top: 1rem;
    z-index: 2;
    font-size: 1rem;
    background-color: transparent;
    -webkit-transition: all 300ms;
    transition: all 300ms;
    border: 0;
    //margin: 0.35rem 0.5rem 0;
    padding: 0.625rem 2.5rem 0.625rem 0.75rem;
    border-radius: 0;
    min-width: auto;

    &:hover {
        background-color: transparent;
    }

    //@include respond-to(medium-screens) {
    //  font-size: 1.125rem;
    //  margin-top: 0;
    //}

    // &:active {
    //     border: 1px solid darken($light-blue, 5%);
    // }
    .btn_toggle-menu_icon {
        position: absolute;
        left: 6px;
        bottom: 0;
        transform: translateY(-50%);
        display: block;
        width: 1.6rem;
        height: 2px;
        background-color: $kleur-almost-black;
        //border-radius: 2px;
        transition: all 350ms;

        //@include respond-to(medium-screens) {
        //  width: 1.5rem;
        //}

        &:before,
        &:after {
            content: "";
            background-color: $kleur-almost-black;
            width: 100%;
            height: 2px;
            position: absolute;
            top: -10px;
            left: 0;
            border-radius: 0;
            transition: all 350ms;
        }

        &:after {
            bottom: 5px;
            top: auto;
        }
    }

    &.active {
        .btn_toggle-menu_icon {
            width: 1.6rem;
            right: 0.35rem;
            background-color: transparent;

            &:before {
                top: -6px;
                transform: rotate(45deg);
                width: 21px;
                left: -2px;
            }

            &:after {
                bottom: 6px;
                transform: rotate(-45deg);
                width: 21px;
                left: -2px;
            }
        }
    }

    //@include respond-to(xlarge-screens) {
    //  // color: #f00;
    //}
}

.footer {
    a {
        text-decoration: none;
    }
}

#registrationNavbar {

    li.close-icon {
        padding: 9px 12px !important;
    }

    .phone-icon {
        padding: 9px 12px !important;
    }
}

.lens {
    @media (max-width: 767.5px) {
        &:before {
            background-image: url(/theme/vrijopnaam/svg/shape.svg);
            background-repeat: no-repeat;
            background-position: center;
            width: 20px;
            height: 20px;
            content: "";
            display: inline-block;
            margin-right: 1.8rem;
        }
    }
    @media (min-width: $breakpoint-medium) {
        background-image: url(/theme/vrijopnaam/svg/shape.svg);
        width: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        height: 12px;
    }

    span {
        font-size: 2rem;
        line-height: 3rem;
        font-weight: 500;
        @media (min-width: $breakpoint-medium) {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
            &:hover {
                color: $kleur-blue-wcag;
            }
        }
    }
}

#desktop-nav, #registrationNavbar {
    position: fixed;
    top: 0;
    transition: top 0.3s linear;
}

#registrationNavbar {

    .usp-vwo-navbar {

        @media (max-width: $breakpoint-large) {
            display: none;
        }

        display: flex;
        justify-content: flex-start;
        gap: 1.6rem;
        flex-grow: 1;
        padding-left: 2rem;

        div {
            display: flex;
            align-items: center;
            font-size: 1.4rem;

            &::before {
                content: "";
                display: block;
                background-image: url('/theme/vrijopnaam/svg/vinkje-blue.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 15px;
                height: 15px;
                margin-right: 0.4rem;
            }
        }
    }
}
