.c-accordion-container {
  background-color: $kleur-grey-light-6;
  h2 {
    text-transform: uppercase;
    @media (min-width: $breakpoint-large) {
      font-size: $h2-font-size-large;
    }
  }
  a[href^="mailto"] {
    color: #393C3F;
  }
}

.c-question-wrapper {
  background-color: $kleur-white;
  border: 1px solid $kleur-grey-light-3;
  border-radius: 8px;
  .c-accordion__link {
    font-family: $base-font-family;
  }
}

.c-accordion__link {
  display: block;
  text-align: left;
  color: $kleur-almost-black;
  font-size: 1.8rem;
  font-weight: $font-weight-light;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $kleur-almost-black;
  }
  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107";
    float: right;
    transition: all 0.5s;
  }
  &[aria-expanded=true]:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

#accordion {
  h3 {
    font-size: 2.2rem;
    line-height: 1.25;
  }
}