.c-circle {
  padding: 1.6rem 0;
  border-radius: 50px;
  width: 56px;
  height: 56px;
  display: inline-block;
  text-align: center;
}

.c-circle--orange {
  background-color: $kleur-orange;
}