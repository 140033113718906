footer {
    background-color: $kleur-almost-black;
    font-size: 0.875rem;
    font-weight: $font-weight-light;
    color: $kleur-white;

    &.c-registration-form__footer {
        color: $kleur-almost-black;
        background-color: $kleur-white;
    }

    ul {
        font-size: 14px;
    }

    h5 {
        font-family: $base-font-family;
        font-size: 14px;
        font-weight: $font-weight-bold;
        color: $kleur-white;
    }

    input {
        font-size: 0.875rem;
    }

    h2 {
        font-size: 1.4rem;
        color: $kleur-white;
        font-family: $base-font-family;
        text-transform: none;
        line-height: 1.25;
    }

    &.mt-auto.pt-xxl.pb-m {
        position: relative;
    }

    @media screen and (max-width: 1024px) {
        .c-registration-form__action-buttons .c-step__buttons {
            align-self: end;
        }

        &.c-registration-form__footer-step-12 .c-registration-form__action-buttons .c-step__buttons {
            width: 100%;
        }
    }
}

.usp-footer {
    display: block;

    & span {
        font-size: 1.4rem;
    }
}

#registration {

    .c-registration-form__footer {
        height: auto;

        @media (min-width: $breakpoint-large) {
            height: 135px;
        }

        @media (max-width: $breakpoint-medium) {

            .c-registration-form__footer--right-step-1,
            .c-registration-form__footer--right-step-3,
            .c-registration-form__footer--right-step-4,
            .c-registration-form__footer--right-step-5,
            .c-registration-form__footer--right-step-7,
            .c-registration-form__footer--right-step-9,
            .c-registration-form__footer--right-step-17,
            .c-registration-form__footer--right-step-18 {
                display: none;
            }
        }
    }

    .c-registration-form__footer--left {
        @media (min-width: $breakpoint-large) {
            border-top: 4px solid $kleur-blue-dark;
        }
    }

    /*
    .c-registration-form__footer--right {
      @media (min-width: $breakpoint-large) {
        border-top: 4px solid $kleur-blue-dark;
      }
    }
    */

    .footer-button-container {

        .textual {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            @media (min-width: $breakpoint-large) {
                flex-direction: row;
                align-items: center;
            }
        }

        .next-btn-step12 {
            display: none;
            max-height: 40px;
            padding: 8px 16px;
            flex-wrap: nowrap;
            align-items: center;
            min-width: max-content;

            @media (max-width: $breakpoint-medium) {
                padding: 8px 8px;
            }
        }

        @media (max-width: $breakpoint-medium) {

            #mailButton {
                margin-right: .8rem !important;
            }
        }
    }


    footer {
        &.c-registration-form__footer {
            width: 100vw !important;
            background-color: white;

            @media (max-width: $breakpoint-medium) {

                border-top: 4px solid #55bde6;
            }

            .next-btn-step12 {

                @media (max-width: 1023px) {
                    visibility: hidden;
                    display: flex;
                }
                @media (min-width: 1024px) {
                    display: none;
                }

            }

            .blue-sun {
                display: none;
            }

            @media (min-width: $breakpoint-large) {
                background: linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(197, 232, 255) 50%);


                .footer-container {
                    display: flex;
                    flex-direction: row-reverse;
                    max-width: 1428px;
                    margin-left: auto;
                    margin-right: auto;
                    height: 100%;

                    .c-footer-layout-container {
                        background-color: #c5e8ff;
                        z-index: 1;
                    }

                    .usp-social-proof-footer-container {
                        display: flex;
                        width: 100%;
                        position: relative;

                        & .footer-contrast-line {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            height: 4px;
                            background-color: #c5e8ff;
                            margin-left: calc((100vw - 100%) / -2);
                            width: 100vw;
                        }
                    }
                }

                .c-footer-layout {
                    margin-left: auto;

                    .text-placeholder {
                        font-size: 1.4rem;
                        margin: 0;
                        padding-right: 2rem;
                    }

                    .blue-sun {
                        display: block;
                    }
                }

                .c-footer-layout, .c-registration-form__action-buttons, .footer-button-container {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    justify-content: space-between;
                }

                .footer-button-container {
                    background-color: white;
                }

                .c-footer-layout {
                    padding: 0 2.4rem;
                    background-color: #c5e8ff;
                    position: relative;
                    justify-content: flex-start;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 2.4rem; /* Matches the left padding */
                        right: 2.4rem; /* Matches the right padding */
                        height: 4px;
                        background-color: #6dcef5;
                    }

                    .c-footer-layout-left {
                        display: flex;
                        flex-direction: column;
                        padding-right: 2rem;
                    }

                    .c-footer-layout-price {

                        .total {

                            span {
                                font-weight: bold;

                                &.geschatteKostenMaand {
                                    font-size: 2.8rem;
                                }

                                &:not(:first-of-type) {
                                    font-size: 1.4rem;
                                }
                            }
                        }
                    }
                }

                .c-registration-form__action-buttons {
                    padding: 2.4rem 2.4rem 0 2.4rem;
                    flex-wrap: nowrap;
                    margin-left: 0;
                }
            }


            & > .container {
                text-align: center;
                min-width: 100vw;
                margin: 0;

                & > .row {
                    margin-right: auto;
                    margin-left: auto;
                    max-width: 1427.9px;

                }

            }

            @media (max-width: $breakpoint-large) {

                .c-footer-layout {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 0.8rem 1.6rem;
                    gap: 2rem;

                    .c-footer-layout-price {
                        display: flex;
                        align-items: baseline;

                        span {
                            font-size: 1.4rem;
                        }

                        p, span:first-of-type {
                            font-size: 22px;
                            font-weight: bold;
                        }
                    }

                    p {
                        font-size: 14px;
                        margin: 0;
                        padding-bottom: 0.5rem;
                        line-height: 1.36rem;
                    }

                    a {
                        white-space: nowrap;
                        font-size: 1.4rem;
                    }

                    .c-footer-layout-right {
                        display: flex;

                        a {
                            line-height: 1.36rem;
                        }
                    }
                }
            }
        }

        .kolom--rechts {
            background-color: white;
        }

    }

    .social-proof-footer-container {
        display: none;
    }

    @media (min-width: $breakpoint-large) {

        .usp-social-proof-footer-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
            background-color: white;

            .social-proof-footer-container {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                padding: 0 2.4rem;
                gap: 2.4rem;
                justify-content: space-between;

                .social-proof-footer,
                .usp-klantenvertellen-footer,
                .usp-consumentenbond-footer {
                    font-size: 1.2rem;

                    span {
                        font-size: 1.2rem;
                    }
                }

                svg, .svg {
                    width: 55px;
                }
            }
        }
    }

    .usp-footer {

        display: none;

        @media screen and (min-width: 1024px) {
            display: inline-block;
            text-align: left;
            position: relative;
            padding: 0 2.4rem;
            max-width: 702px;
        }

        & > span {
            font-weight: bold;
            font-size: 1.8rem;

            &::before {
                content: "";
                display: block;
                background-image: url('/theme/vrijopnaam/svg/vinkje-blue.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 15px;
                height: 15px;
                position: absolute;
                left: 0; // Position it at the start of the span
                top: 0.4rem
            }
        }
    }

    .vwo-usp-footer-mobile {
        display: none;
        background-color: #5dafd0;
        padding: 1rem 1.6rem;

        @media (max-width: $breakpoint-large) {
            display: block;

            span {
                height: auto;
                font-size: 1.4rem;
                font-weight: bold;
            }
        }
    }

    @media (max-width: $breakpoint-medium) {

        footer.c-registration-form__footer {
            border-top: none;
        }
    }

    //Aanmeldfunnel | Specifieke CTA button teksten & copy Footer

    .vwo-nextbutton {
        display: flex;
    }
}

.c-footer-svg {
    background-image: url(/theme/vrijopnaam/svg/illustratie-lines-extra-groot.svg);
    height: 81px;
    background-repeat: no-repeat;
    background-position: calc(50% + 62px);
}

.c-social-media {
    display: flex;
    list-style: none;

    li {
        margin-right: 2.8rem;
        margin-bottom: 0 !important;
        align-self: center;
        display: flex;
    }
}

#mce-EMAIL, #mc-embedded-subscribe {
    font-size: 14px !important;
}

#mce-responses .response {
    background-color: $kleur-blue;
    color: $kleur-almost-black;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 8px;
    font-size: 14px;
}

#mce-responses a {
    color: $kleur-almost-black;
    text-decoration: underline;
}

// chatbot widget

#fc_frame {
    @media (max-width: 768px) {
        display: none;
    }
}

// Sticky subscribe button

.sticky-subscribe-button {
    display: none;

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        background-color: white;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11111;
        border-top-left-radius: 16px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 -1px 0 0 #eaeaea;

        & a {
            margin: 1.2rem;
            max-width: 288px;
            width: 100%;
        }
    }
}