#klaro {

  span, div {
    line-height: 1.22;
  }

  .cm-modal.cm-klaro {
    max-width: 823px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);
  }

  .cookie-notice {
    max-width: 1380px;
    background-color: white;
    position: fixed;
    bottom: 0;
    top: auto;
    z-index: 9999;
    left: 0;
    right: 0;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;

    .cn-body {

      p {
        color: black;

        a {
          color: black;
          text-decoration: underline black;
        }
      }

      .cn-ok {
        flex-flow: column-reverse;
        justify-content: left;

        @media (min-width: $breakpoint-medium) {
          flex-flow: row-reverse;
        }

        .cm-link.cn-learn-more {
          margin-left: unset;
          flex-grow: unset;
          width: 20rem;
          height: 40px;
          padding: 9px 16px;
          border-radius: 8px;
          background-color: #eaf6fe;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          color: black;

          @media (min-width: $breakpoint-medium) {
            margin-left: auto;
          }
        }
      }

      .cn-buttons {
        display: flex;
        flex-flow: row-reverse;
        width: auto;
      }
    }
  }

  .cm-btn.cm-btn-success, .cm-btn.cm-btn-danger.cn-decline {
    width: 119px;
    height: 40px;
    margin: 14px 16px 19px 0;
    padding: 9px 16px;
    border-radius: 8px;
    background-color: #fbdc2d;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: black;
  }

  .cm-btn.cm-btn-success.cm-btn-info.cm-btn-accept {
    background-color: #6dcef5;
  }

  .cm-body {
    padding: 0;
  }

  .cm-list-input {
    opacity: unset;
    width: 24px;
    height: 24px;
  }

  .slider.round {
    display: none;
  }

  .cm-services {
    display: flex;
  }

  ul.cm-purposes {
    display: flex;
    flex-direction: column-reverse;
  }

  div.cm-header {
    border-bottom: none;
    padding: 0 0 33px 0;

    h1.title {
      text-transform: none;
      padding: 0 0 16px 0;
      line-height: 0;

      span {
        font-weight: bold;
      }
    }

    p {
      margin: 0;
      padding-inline-end: 6rem;
    }

    a {
      color: black;
      text-decoration: underline black;
    }

    svg {
      transform: scale(1.7);
    }
  }

  .cm-footer {
    border: none;
    padding: 0;
  }

  li.cm-purpose {
    display: block;
    padding-left: 4rem;
    margin: 0;
    min-height: unset;
    line-height: 24px;

    label {
      margin: 0;
      line-height: 1.22;
      padding-inline-end: 3rem;
    }

    &:nth-child(2) {
      padding-bottom: 18px;
    }

    &:nth-child(3) {
      padding-bottom: 18px;
    }

    &:nth-child(4) {
      padding-top: 25px;
      padding-bottom: 18px;

      .cm-list-input {
        top: 24px;
      }

      .cm-caret a {
        margin-top: 25px;
      }
    }

    .cm-content.expanded {
      padding-left: 4rem;

      label.cm-list-label {
        padding-left: 0;
      }

      .cm-list-input {
        top: 0;
      }
    }
  }

  .cm-purpose.cm-toggle-all {
    border-top: none;
    border-bottom: solid #dddede 1px;
    padding-top: 0;
    padding-bottom: 25px;
    margin: 0;
  }

  .cm-list-description span {
    font-style: italic;
  }

  .cm-list-title, .cm-required {
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: black;
  }

  .cm-caret {
    padding-right: 0.5rem;

    a {
      display: none;

    }
  }

  .cm-btn {
    margin: 0;
  }

  .cm-btn.cm-btn-decline.cm-btn-danger.cn-decline, .cm-btn.cm-btn-success.cm-btn-accept-all {
    display: none;
  }

  .cm-btn.cm-btn-success.cm-btn-info.cm-btn-accept {
    width: 103px;
    height: 40px;
    margin: 24px 0 0 0;
    padding: 9px 16px;
    border-radius: 8px;
    background-color: #fbdc2d;
    color: black;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid black;
    padding: 11px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 4px;
    position: absolute;
    left: 0;
  }

  input[type="checkbox"][disabled] + label:before {
    background-color: lightgray;
  }

  .klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label::before {
    background: none;
  }

  input[type="checkbox"]:checked + label:before {
    background-image: url(/theme/vrijopnaam/images/check-mark-svgrepo-com.svg);
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center;
  }

  .cn-changes {
    text-decoration: none;
    font-weight: bold;
  }

}

.klaro .context-notice {
  background-repeat: no-repeat;
  background-size: cover;
  text-shadow: 1px 1px white;
}

.zonnestroom-en-gas .context-notice {
  background-image: url(/theme/vrijopnaam/images/video-zonnestroom-gas.png);

  p:first-of-type {
    background-color: #faf5dd;
  }
}

.zelfopwekken .context-notice {
  background-image: url(/theme/vrijopnaam/images/video-zelfopwekken.png);

  p:first-of-type {
    background-color: #eaf6fe;
  }
}

.zonnestroom-en-gas .context-notice, .zelfopwekken .context-notice {

  p:first-of-type {
    border-radius: 8px;
    opacity: 0.8;
    font-weight: 700;
    padding: 0.4em;
  }
}

#cookie-button {
  cursor: pointer;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
  display: none;
}
