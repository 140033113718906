.container.px-sm-m.px-lg-l .row table:not(.voor-wie):not(.aanpak) {

    border: 1px solid #DDD;
    width: 100%;
    margin-bottom: 16px;
    color: #212529;

    th {
        background-color: $kleur-blue-light-2;
    }

    th, td {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 0 solid #dee2e6;
    }

    tr:nth-of-type(odd) {
        background-color: rgba(57, 60, 63, 0.05);
    }
}

#prijsOpbouw {

    .modal-dialog {
        max-width: 400px;
    }


    #accordion {
        border-top: 1px solid $kleur-grey-light-3;

        .card-body {
            padding: 0 1.6rem 1.6rem;
        }

        #toelichting-prijsopbouw {
            padding: 1.6rem;
            border: none;
            border-radius: 0;
            background-color: transparent;
        }

        .c-question-wrapper {
            border-radius: 0 0 4px 4px;
        }
    }

    table {
        margin-bottom: 0;
    }

    details > summary {
        /* remove default list-style */
        list-style: none;
        position: relative;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
    }

    summary img {
        width: 16px;
        margin-left: -3rem;
    }

    summary span {
        padding-left: 1rem;
        font-weight: 700;
        font-size: 1.4rem;
    }

    summary::-webkit-details-marker {
        display: none
    }

}

#toonprijsopbouw {

    border: 0;
    width: 100%;
    font-size: 1.5rem;

    td, th {
        background-color: #FFF;
        padding: 0.5rem 0 0.5rem 0;
    }

    small {
        font-weight: normal;
    }

    .eenheid {
        color: $kleur-blue-dark;
        font-size: 1.3rem;
        font-weight: 700;
        padding: 0.7rem 0 0 0.6rem;
        width: 7rem;
    }

    .highlight {
        color: $kleur-blue-dark;
        font-weight: normal;
    }
}


