.c-search-form {
    .c-search-form__button {
        right: 0.8rem;
        z-index: 3;
    }

    .form-clear {
        position: absolute;
        top: 25%;
        left: calc(100% - 130px);
    }

    .c-button-back {
        font-weight: 700;
    }
}

.c-registration-form {
    h1 {
        width: auto;
    }
}

.c-registration-form__fields-container {
    label, .label {
        font-size: 1.8rem;
        line-height: 22px;
        font-weight: $font-weight-bold;
        margin-bottom: 8px;
    }

    .inschatten {
        text-decoration: underline;
        font-weight: $font-weight-light;
        padding: 0;
        color: #393c3f;
    }

    .checkbox {
        label {
            font-weight: $font-weight-light;
        }
    }

    .c-button-container {
        display: flex;
        flex-wrap: wrap;

        .form-group {
            margin-right: 3.2rem;
        }
    }
}

.c-registration-main {
    background: linear-gradient(90deg, rgba(109, 206, 245, 1) 50%, rgba(197, 232, 255, 1) 50%);
    /*overflow-y: hidden;
    overflow-x: hidden;
    overflow-y: scroll;*/
    h1 {
        font-size: 3.3rem;
        font-family: $base-font-family;
        font-weight: $font-weight-bold;
        text-transform: none;
        line-height: 1.4;
        margin-bottom: 0 !important;

        @media (min-width: $breakpoint-medium) {
            font-size: 4rem;
        }
    }

    .c-step__buttons {
        //justify-content: flex-end !important;
    }

    &.last-step {
        background-color: rgba(109, 206, 245, 1);
        background: unset;
        height: unset;
    }

    fieldset {
        margin-right: 1.1rem;
    }

    .container-registration-wrapper {
        overflow: auto;
        position: relative;
    }
}

.c-registration-form__footer-step-12 {
    .c-step__buttons {
        display: flex;
        justify-content: space-between;
        @media (min-width: $breakpoint-medium) {
            justify-content: flex-end;
        }
    }
}

h1.h2.text-black {
    margin-bottom: 32px !important;

    @media (max-width: $breakpoint-medium) {
        margin-bottom: 16px !important;
    }
}

body,
html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

.welcome-page {
    background-color: rgba(109, 206, 245, 1);

    .c-welcome .row {
        flex-direction: column;

        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.klaar {
    background-color: white;
    margin: 0;
    display: flex;
    max-width: unset;
    height: 131px;
    align-items: center;
    text-align: right !important;
}

#mailButton, #offerteSubmitButton, .kassabonButton {
    max-height: 40px;
    padding: 8px 16px;
    align-items: center;
    white-space: nowrap;
}

.c-registration-form-container {
    /* border: 2px dotted red; */
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 56.8px;
    margin-bottom: 2rem;

    @media (min-width: $breakpoint-medium) {
        height: min-content;
        padding-bottom: 1rem;
    }

    -ms-overflow-style: none;
}

.c-registration-form__costs-wrapper {

    text-align: left;

    @media (min-width: $breakpoint-large) {
        border-top: 4px solid #55bde6;
        margin-left: 8px;
        margin-right: 8px;
        padding-top: 24px !important;
    }

    p.heading {
        font-size: 1.4rem;
        font-weight: $font-weight-light;
        line-height: 2rem;
        margin-bottom: 0;
        @media (min-width: $breakpoint-medium) {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }

    p.total {
        font-size: 2.1rem;
        font-weight: $font-weight-bold;
        /*line-height: 3rem;*/
        margin-bottom: 0;
        @media (min-width: $breakpoint-medium) {
            font-size: 2.8rem;
            line-height: 2.4rem;

            .geschattekosten, .geschattekostenMaandAside {
                font-size: 2.8rem;
                line-height: 2.4rem;
            }
        }

        span {
            font-size: 1.5rem;
            font-weight: $font-weight-bold;
            line-height: 2rem;
        }
    }

    .textual {
        @media (min-width: $breakpoint-large) {
            background-image: url(/theme/vrijopnaam/svg/zon-bl.svg);
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: 90%;
        }
    }

    p.euro-sign {
        @media (max-width: $breakpoint-medium) {
            font-size: 1.5rem;
            line-height: 2.6rem;
        }
    }
}

.c-registration-form-container__step-1 {
    .c-steps-panel {
        /*margin-bottom: 41px;*/
        margin-bottom: 0;
        padding-bottom: 2rem;
    }

    .footer-text {
        display: none;

        p {
            display: flex;
            align-items: center;
            margin: 0;

            img {
                width: 20px;
                border-radius: 0;
                padding-bottom: 0.3rem;
                margin-right: 0.9rem;
                align-self: baseline;
                margin-top: 0.4rem;
            }
        }
    }
}

.c-registration-form-container__step-3,
.c-registration-form-container__step-4 {
    .row.wrapper {
        height: calc(100vh - 41px);
    }
}

.c-registration-form__footer--right-step-1.order-lg-1 {
    display: none;
    @media (min-width: $breakpoint-medium) {
        display: block;
        .c-step__buttons {
            display: none !important;
        }
    }
}

.c-registration-form__footer--right.order-lg-2 {
    @media (min-width: $breakpoint-large) {
        background-color: $kleur-blue-light-2;
        border-top: none;
        padding-top: 0 !important;
        padding-bottom: 24px !important;
    }
}

.c-steps-panel {

    margin-bottom: 0;
    padding-bottom: 5rem;

    /*margin-bottom: 150px;*/
    @media (min-width: $breakpoint-medium) {
        padding-bottom: 2rem;
    }


    @media (min-width: $breakpoint-large) {
        margin-top: 55px;
        margin-bottom: 0;
        padding-right: 2.4rem;
        padding-left: 2.4rem;
        /*margin-bottom: 131px;*/
    }

    &.modal-gegevens {
        padding: 0;
        margin: 0;
    }

    .flow-steps {
        @media (min-width: $breakpoint-large) {
            margin-top: 32px !important;
        }
        @media (min-width: $breakpoint-xlarge) {
            margin-top: 104px !important;
        }

        h2 {
            color: $kleur-almost-black;
            font-weight: 700;
            font-size: 1.8rem;
            text-transform: none;
        }

        a {
            color: #393c3f;
        }
    }

    .contractName-aside {
        display: none;
    }

    .progress {
        height: 60px;


        @media (min-width: $breakpoint-medium) and (max-width: 1023px) {
            &:not(.progress--complete) {
                width: 100%;
                left: 0;
            }
        }

        .textual {
            max-height: 60px;
            justify-content: center;

            p {
                line-height: 2.2rem;
                font-weight: $font-weight-bold;
                margin-bottom: 0;
            }
        }

        .left {
            margin-left: 16px;

            p {
                font-size: 1.3rem;
                @media (min-width: $breakpoint-xlarge) {
                    font-size: 1.5rem;
                }
            }
        }

        .right {
            margin-right: 16px;
            position: relative;

            p {
                font-size: 1.3rem;
                font-weight: 300;
                text-align: right;
                @media (min-width: $breakpoint-xlarge) {
                    font-size: 1.5rem;
                }
            }
        }

        .step-link {
            display: none;
        }

        .contractName-aside {
            display: none;
        }
    }

    .contract-welkomstKorting {
        display: none;
    }
}

.c-registration-form__action-buttons {
    @media (max-width: $breakpoint-large) {
        border-top: 2px solid $kleur-blue-light-2;
        height: 100%;
        padding: 0.8rem 3.2rem;
        justify-content: end;

        .step-12.footer-buttons {
            display: flex;
            width: 100%;
        }
    }
    @media (min-width: $breakpoint-medium) {
        height: 100%;
    }
}

/*
.bg:before {
  position:absolute;
  left:0em;
  content:'';
  height: 100vh;
  width:800em;
  z-index:-1
}
*/

.bg-blue:before {
    background-color: #6dcef5;
}

.bg-blue-light-2:before {
    background-color: #c5e8ff;
}

.bg-bleed-left:before {
    left: -400em;
}

.bg-bleed-right:before {
    left: 0;
    right: auto;
}

@media (max-width: $breakpoint-large) {
    .bg-bleed-right:before {
        left: -200em;
    }
}

// Overwritte Bootstrap Popover

.bs-popover-right > .arrow::before {
    left: 4px;
}

.bs-popover-right > .arrow::after {
    left: 14px;
    border-width: 0.6rem 2rem 0.6rem 0;
    top: 4px;
}

.popover-header {
    border-bottom: none;
    padding-bottom: 0;
}

.popover-body {
    padding-top: 8px;
}

legend.c-step__heading {
    margin-bottom: 32px;
}


/*
* Stappen formulier
*/

/* Feedback kleuren */

:root {
    --color-warning-darker: hsl(46, 100%, 41%);
    --color-warning-dark: hsl(46, 100%, 51%);
    --color-warning: hsl(46, 100%, 61%);
    --color-warning-light: hsl(46, 100%, 71%);
    --color-warning-lighter: hsl(46, 100%, 81%);

    --color-success-darker: hsl(94, 48%, 36%);
    --color-success-dark: hsl(94, 48%, 46%);
    --color-success: hsl(94, 48%, 56%);
    --color-success-light: hsl(94, 48%, 66%);
    --color-success-lighter: hsl(94, 48%, 76%);

    --color-error-darker: hsl(349, 75%, 31%);
    --color-error-dark: hsl(349, 75%, 41%);
    --color-error: hsl(349, 75%, 51%);
    --color-error-light: hsl(349, 75%, 61%);
    --color-error-lighter: hsl(349, 75%, 71%);
}

/* Feedback opmaak */

.c-registration-form__fields-container {

    ul {
        list-style: none;
        padding: 0;

        & li {
            background-color: #FAD728;
            border-left: solid #FF7301 2px;
            padding: 10px 20px 10px 6px;
            font-size: 1.5rem;
            color: #323537;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            max-width: fit-content;
        }

    }

}

/* Algemene opmaak regels */

form.mt-xxl.flow-form.c-registration-form {
    @media (min-width: $breakpoint-small) {
        margin-top: 32px !important;
    }

    @media (min-width: $breakpoint-xlarge) {
        margin-top: 104px !important;
    }
}

.c-registration-form__fields-container {
    input[type="text"] {
        border: 1px solid $black;
        padding: 1rem 1rem 1rem 3rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
    }

    input[type="number"] {
        border: 1px solid $black;
        padding: 1rem 1rem 1rem 4rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
    }

    input[type="email"] {
        border: 1px solid $black;
        padding: 1rem 1rem 1rem 3rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
    }

    input[type="tel"] {
        border: 1px solid $black;
        padding: 1rem 1rem 1rem 3rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
    }

    input[type="date"] {
        border: 1px solid $black;
        text-transform: uppercase;
        padding: 1rem 1rem 1rem 3rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
        background-color: #ffffff;
    }

    select {
        border: 1px solid #393c3f;
        padding: 0.5rem 1rem 0.5rem 4rem;
        border-radius: 8px;
        margin-bottom: 2rem;
        align-self: flex-start;
        background-repeat: no-repeat;
        background-position: 1rem center;
    }
}

div[class^='step-'] {
    & .form-group {
        margin-right: 2rem;
        margin-bottom: 0;
    }
}

/* Stap 2 - gas en/of electriciteit */

.step-2 {

    .col-sm-2 {
        display: none;
    }

}

/* Stap 3 - waarden gas en electriciteit */

.step-3 {

    .col-label-icon {
        padding-left: 1.8rem;
        width: max-content;
    }

    .estimation {
        display: none;
    }

    .form-group.row {
        display: flex;
        flex-direction: column;
        margin-bottom: 0 !important;
    }

    div[id^="row_RegistrationFormStep"] {
        display: flex;
        flex-direction: column;
    }

    button[type="submit"] {
        background: transparent;
        border: 0;
    }

    .checkbox-label {
        margin-bottom: 25px;
    }

    #RegistrationFormStep3AB_stroomJaarVerbruikNormaal,
    #RegistrationFormStep3AB_stroomJaarVerbruikDal,
    #RegistrationFormStep3AB_stroomJaarVerbruikEnkel {
        background-image: url(/theme/vrijopnaam/images/kwh.png);
        background-position: 92% center;
        background-size: 47px;
        background-repeat: no-repeat;
    }

    #RegistrationFormStep3AB_gasVerbruikJaar {
        background-image: url(/theme/vrijopnaam/svg/gas.svg), url(/theme/vrijopnaam/images/m3-3.png);
        background-position: 1.6rem center, 88% center;
        background-size: 12px, 40px;
        background-repeat: no-repeat, no-repeat;
    }

    #RegistrationFormStep3D_soortHuis {
        background-image: url(/theme/vrijopnaam/svg/huis.svg), url(/theme/vrijopnaam/svg/down-arrow-svgrepo-com.svg);
        width: 228px;
        background-position: 1rem center, 95% center;
        background-size: 20px, 15px;
        background-repeat: no-repeat, no-repeat;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: white;
        color: #393c3f;
    }

    #RegistrationFormStep3C_aantalInwoners {
        background-image: url(/theme/vrijopnaam/svg/personen.svg), url(/theme/vrijopnaam/svg/down-arrow-svgrepo-com.svg);
        //padding-right: 4rem;
        width: 228px;
        background-position: 1rem center, 95% center;
        background-size: 20px, 15px;
        background-repeat: no-repeat, no-repeat;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: white;
        color: #393c3f;
    }

    #RegistrationFormStep3AB_stroomJaarVerbruikNormaal, #RegistrationFormStep3AB_stroomJaarVerbruikDal,
    #RegistrationFormStep3AB_stroomJaarVerbruikEnkel, #RegistrationFormStep3AB_gasVerbruikJaar {
        width: 230px;
    }

    .maxNormaalDal, .maxEnkel, .maxGas {
        background-color: #FAD728;
        border-left: solid #FF7301 2px;
        padding: 10px 20px 10px 6px;
        font-size: 1.5rem;
        color: #323537;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .errorMaxNormaal, .errorMaxEnkel, .errorMaxGas {
        display: none;
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .power-icon {
        position: absolute;
        width: 16px;
        left: 1.6rem;
        top: 3.7rem;
        z-index: 111;

        &.day, &.night {
            display: none;
            top: 4.2rem;
        }
    }

}

.step-3c {
    display: flex;
    flex-wrap: wrap;

    .col-sm-2 {
        display: none;
    }


    .persoon-1,
    .persoon-2,
    .persoon-3,
    .persoon-4,
    .persoon-5 {
        background-repeat: no-repeat;
        padding: 55px 16px 10px;
        background-position: 14px 10px;
    }

    .persoon-1 {
        background-image: url('/theme/vrijopnaam/svg/1-persoon.svg');
    }

    .persoon-2 {
        background-image: url('/theme/vrijopnaam/svg/2-personen.svg');
    }

    .persoon-3 {
        background-image: url('/theme/vrijopnaam/svg/3-personen.svg');
    }

    .persoon-4 {
        background-image: url('/theme/vrijopnaam/svg/4-personen.svg');
    }

    .persoon-5 {
        background-image: url('/theme/vrijopnaam/svg/5-personen.svg');
    }

}

.step-3d {

    display: flex;
    flex-wrap: wrap;

    .col-sm-2 {
        display: none;
    }

    .appartement,
    .tussenwoning,
    .hoekwoning,
    .twee-kap,
    .vrijstaand {
        background-repeat: no-repeat;
        padding: 55px 16px 10px;
        background-position: 16px 10px;
    }

    .appartement {
        background-image: url('/theme/vrijopnaam/svg/appartement.svg');
    }

    .tussenwoning {
        background-image: url('/theme/vrijopnaam/svg/tussenwoning.svg');
    }

    .hoekwoning {
        background-image: url('/theme/vrijopnaam/svg/hoekwoning.svg');
    }

    .twee-kap {
        background-image: url('/theme/vrijopnaam/svg/twee-kap.svg');
    }

    .vrijstaand {
        background-image: url('/theme/vrijopnaam/svg/vrijstaand.svg');
    }

}

/* Stap 4 - heb je zonnepanelen */

.step-4 {

    display: flex;
    flex-wrap: wrap;

    .col-sm-2 {
        display: none;
    }
}

[name="RegistrationFormStep4A"] {

    #RegistrationFormStep4A_opwekNormaalTarief,
    #RegistrationFormStep4A_opwekDalTarief,
    #RegistrationFormStep4A_aantalOpwekEp {
        background-image: url(/theme/vrijopnaam/svg/current.svg);
    }

    #RegistrationFormStep4A_aantalOpwekEpSlider {
        margin-bottom: 2rem;
    }

}

.step-4a {

    #RegistrationFormStep4A_opwekNormaalTarief,
    #RegistrationFormStep4A_opwekDalTarief,
    #RegistrationFormStep4A_opwekEnkelTarief {
        background-image: url(/theme/vrijopnaam/svg/current.svg), url(/theme/vrijopnaam/images/kwh.png);
        background-position: 1rem center, 92% center;
        background-size: 12px, 43px;
        background-repeat: no-repeat, no-repeat;
        display: flex;
        flex-direction: column;
        min-width: 249px;
    }

    input[type="range"] {
        //width: 180px;

        @media (min-width: $breakpoint-medium) {
            //width: 250px;
        }
    }

    .form-control {
        display: flex;
        width: fit-content;
        border: none;
        padding: 0;
        background-color: transparent;

        input[type="text"] {
            padding: 1rem 1rem 1rem 1.8rem;
            width: 5ch;
            text-align: center;
            margin-bottom: 0;
        }


        #minPon,
        #maxPon {
            padding-top: 1.125rem;
        }

        #minPon {
            margin-right: 1rem;
        }

        #maxPon {
            margin-left: 1rem;
        }


        .row_maxPon {
            display: flex;
            line-height: 1.5;
        }

        .slider {
            display: flex;
            background-color: white;
            width: 100%;
            border-radius: 8px;
            padding-right: 2rem;
            padding-left: 0;
            height: 40px;
        }

    }

    .input-group-append {
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: $breakpoint-medium) {
            margin-left: 2rem;
        }
    }

    .maxNormaalDal, .maxEnkel {
        background-color: #FAD728;
        border-left: solid #FF7301 2px;
        padding: 10px 20px 10px 6px;
        font-size: 1.5rem;
        color: #323537;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .errorMaxNormaal, .errorMaxEnkel {
        display: none;
        margin-top: -10px;
        margin-bottom: 10px;
    }
}

/* step 4a slider */

.step-4a .form-control .row_opwekEp {
    display: flex;
    line-height: 1.5;
    flex-direction: unset;
    width: 249px;
}

.row_opwekEp {
    display: flex;
    line-height: 1.5;
}

.step-4a .form-control {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    padding: 0;
    flex-direction: unset;
    margin-bottom: 2rem;
}

.step-4a .input-group-append {
    margin-left: 2rem;
}

.step-4a #RegistrationFormStep4A_aantalOpwekEp {
    line-height: 1.5;
}

.step-4a #RegistrationFormStep4A_aantalOpwekEpSlider {
    margin-top: 1.4rem;
    margin-left: 20px;
}


/* Stap 5 - Wil je stroom opwekken in onze parken */

.step-5 {
    display: flex;
    flex-wrap: wrap;

    .col-sm-2 {
        display: none;
    }

}

fieldset a {
    color: #393C3F !important;
}

.c-step__intro-text p, .c-step__intro-text_extra p {
    max-width: 70ch;
    margin-bottom: 32px;
}

.step-5a {

    .gratis_paneel_slide {
        width: fit-content;
    }

    input[type="range"] {
        width: 165px;

        @media (min-width: $breakpoint-medium) {
            width: 250px;
        }
    }


    .form-control {
        display: flex;
        width: fit-content;
        border: none;
        padding: 0;
        background-color: transparent;

        input[type="text"] {
            padding: 1rem;
            width: 4ch;
            text-align: center;
        }

        #minPon,
        #maxPon {
            padding-top: 1.125rem;
        }

        #minPon {
            margin-right: 1rem;
        }

        #maxPon {
            margin-left: 1rem;
        }


        .row_maxPon {
            display: flex;
            line-height: 1.5;
        }

        .slider {
            display: flex;
            background-color: white;
            width: fit-content;
            border-radius: 8px;
            padding-right: 1rem;
            padding-left: 1rem;
        }
    }

    .input-group-append {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #RegistrationFormStep5A_aantalPon {
        line-height: 1.5;
        padding: 1rem 1rem 1rem 1rem;
        width: 4ch;
        text-align: center;
        margin-bottom: 0;
        align-self: unset;
        min-width: 3rem;
        min-height: 3rem;
    }

    .gratis_paneel_slide {
        margin-top: .3rem;
        margin-bottom: 2rem;

        img {
            max-width: 120px;
            height: auto;
        }

    }
}

.step-5b {

    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-medium) {
        flex-direction: row;
    }


    .form-group {
        .col-sm-2 {
            display: none;
        }
    }

    .form-group.row {
        display: flex;
        flex-direction: column;
    }

}

.form-group {

    button[type="submit"] {
        width: max-content;
        min-width: 12ch;
        text-align: left;
    }

}

#RegistrationFormStep5C_zonnepark {
    margin-left: 1.5rem;
    padding: 1rem;
    border-radius: 4px;
}


/* Stap 6 - Kassabon */

.step-6 {

    .row {
        display: flex;
        gap: 0.8rem;
    }

    .kassabon {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 1rem;
        height: unset;
        min-height: 440px;
        z-index: 10 !important;
        width: 100%;
        background-color: white;
        border-radius: 5px;
        //margin-left: 6px;
        margin-top: 16px;
        margin-bottom: 2rem;
        cursor: pointer;
        flex: 1;
        min-width: 200px;
        max-width: 600px;

        h5 {
            font-size: 1.5rem;
            text-align: center;
            margin: 0.6rem 0;
            height: 37.5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        hr {
            border: 1px solid var(--blue-light-2);
            margin: 0;
        }

        p {
            font-size: 1.5rem;
        }

        p.bedragKassabon {
            text-align: center;
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 0;
        }

        .voordelen {
            width: 100%;

            p {
                line-height: 1.4;
                margin: 0;
            }
        }

        &:hover {
            box-shadow: black 0 0 0 2px;
        }

        .voordeel {
            display: flex;
            padding: 0.4rem 1.6rem;

            &.first {
                //height: 110px;
            }

            &:before {
                content: "";
                display: block;
                width: 22px;
                height: 20px;
                background-image: url("/theme/vrijopnaam/svg/vinkje-groen.svg");
                background-repeat: no-repeat;
                background-position-y: center;
                flex-shrink: 0;
            }
        }

        @media (min-width: 660px) {

            &.single {

                .voordeel {
                    padding: 0.4rem 1.6rem;

                    &.first {
                        height: 50px;
                    }
                }

                .vinkjeKassabon {
                    width: 20px;
                }

                h5 {
                    margin: 1.4rem 0;
                }
            }
        }

        &.single {
            min-height: unset;
            width: unset;
            display: flex;
            align-items: flex-start;

            h5 {
                text-align: left;
                padding-left: 1.6rem;
            }

            .bottom div {
                justify-content: left;
                padding-left: 1.6rem;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            margin-left: 0;
            margin-top: 28px;
        }

        .bottom {
            margin-top: 20px;
            width: 100%;
            padding-left: .8rem;

            .text-center {
                margin-top: 2rem;
            }

            div {
                display: flex;
                justify-content: center;
                margin-bottom: 0.6rem;

                span {
                    font-size: 1.4rem;
                    font-weight: bold;
                    margin-left: 0.6rem;
                }
            }

            .welkomstKortingContract {

                span {
                    font-weight: 300;
                    margin-left: 0;
                }

                .countdown {
                    //display: none;

                    span {
                        font-weight: bold;

                        &.welkomstKortingBedrag {
                            font-weight: 300;
                        }
                    }
                }
            }
        }

        .checkboxPon {
            padding-left: .8rem;
        }

        .checkboxPon label {
            font-size: 1.3rem;
            margin-top: 1rem;
            padding: 0 1rem 0 3.5rem;
            font-weight: normal;

            .pijltje {
                display: block;
                margin-left: 6rem;
                font-size: inherit;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    left: -3rem;
                    width: 28px;
                    height: 20px;
                    background-image: url("/theme/vrijopnaam/svg/pijltje.svg");
                    background-repeat: no-repeat;
                }
            }

            input[type="checkbox"] {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                border-radius: 4px;
            }

        }

        .checkbox-label {
            display: block;
            position: relative;
            cursor: pointer;
        }

        .checkbox-label input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        .checkbox-label .checkbox-custom {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border-radius: 5px;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
            border: 1px solid #000000;
        }


        .checkbox-label input:checked ~ .checkbox-custom {
            background-color: #fff;
            border-radius: 5px;
            -webkit-transform: rotate(0deg) scale(1);
            -ms-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
            opacity: 1;
            border: 1px solid #000000;
        }


        .checkbox-label .checkbox-custom::after {
            position: absolute;
            content: "";
            left: 12px;
            top: 12px;
            height: 0px;
            width: 0px;
            border-radius: 5px;
            border: solid #009BFF;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(0deg) scale(0);
            -ms-transform: rotate(0deg) scale(0);
            transform: rotate(0deg) scale(0);
            opacity: 1;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
        }


        .checkbox-label input:checked ~ .checkbox-custom::after {
            -webkit-transform: rotate(45deg) scale(1);
            -ms-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
            opacity: 1;
            left: 8px;
            top: 3px;
            width: 6px;
            height: 12px;
            border-width: 0 2px 2px 0;
            background-color: transparent;
            border-radius: 0;
        }


        &.voordeligsteKeuze:before {
            background-color: #c5e8ff;

            html[lang="nl"] & {
                content: "Voordeligste keuze";
            }

            html[lang="en"] & {
                content: "Most economical choice";
            }
        }

        .slimmeMeter {
            background-color: #eaf6fe;
        }

        .meest-gekozen {
            background-color: #c5e8ff;
        }

        &.voordeligsteKeuze:before, .slimmeMeter, .meest-gekozen {
            position: absolute;
            height: 28px;
            line-height: 2;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            font-size: 1.5rem;
            margin-top: -32px;
            z-index: -1;
            width: 94%;
            text-align: center;
            font-weight: 300;
        }

        .bottom {
            padding: 0 !important;
        }
    }

    .contractType {
        background-color: #FAD728;
        border-left: solid #FF7301 2px;
        padding: 10px 20px 10px 6px;
        font-size: 1.5rem;
        color: #323537;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .errorContractType {
        display: none;
        margin-top: 5px;
    }
}

.vwo-cro-kassabon {
    display: none;
}

@media (max-width: $breakpoint-medium) {

    .non-vwo-cro-kassabon {
        display: none;
    }

    .vwo-cro-kassabon {
        display: block;
    }

    html.aanmeld-wrapper,
    html.aanmeld-wrapper body {
        overflow-y: auto;
        overflow-x: hidden;

        main {
            display: flex;
            flex-direction: column;
        }
    }

    #registration-wrapper {
        legend.c-step__heading {
            margin: 0 0 1.6rem 0;

            h1 {
                font-size: 18px;
            }
        }

        .c-step__intro-text,
        .c-step__intro-text_extra {
            p {
                margin-bottom: 1.6rem;
            }
        }
    }

    #vorige-button {
        font-size: 14px;
        margin-bottom: .8rem;

        svg {
            height: 1.2rem;
        }
    }

    .c-registration-form-container__step-12 {
        #registration-wrapper {
            .c-registration-form-container {
                min-height: unset;
                padding-bottom: 0;
            }

            form.mt-xxl.flow-form.c-registration-form {
                margin-top: 24px !important;
            }

            .step-6.vwo-cro-kassabon {
                .kassabon {
                    margin: 3.2rem 0.5rem 0 0.5rem;
                    min-height: auto;

                    .kassabon-header {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: flex-start;
                    }

                    h5 {
                        font-size: 1.4rem;
                        text-align: left;
                        padding: 6px 12px;
                        height: auto;
                        margin: 0;
                    }

                    p {
                        font-size: 1.4rem;
                    }

                    .voordeel {
                        padding: 0 1.2rem;
                        margin: 0.6rem 0;

                        p {
                            line-height: 1.43;
                        }
                    }

                    .welkomstKortingContractNo {
                    }

                    .slimmeMeter,
                    .meest-gekozen {
                        height: 24px;
                        font-size: 1.4rem;
                        margin-top: -2.7rem;
                    }

                    p.total {
                        padding: 6px 12px;
                        text-align: end;
                        margin: 0;
                        line-height: 0;
                        white-space: pre-line;

                        span {
                            font-size: 1.4rem;
                            white-space: nowrap;
                            line-height: 2rem;

                            &:first-of-type {
                                font-weight: bold;
                            }
                        }
                    }

                    .bottom {
                        margin: 0.2rem 0 0;
                    }

                    .text-center {
                        margin-top: 1.8rem;
                    }

                    .welkomstKortingContract,
                    .welkomstKortingContractNo {
                        flex-wrap: nowrap !important;
                        text-align: left;
                        margin: 0;

                        span {
                            font-size: 1.4rem;
                        }

                        div {
                            margin: 0;
                        }
                    }

                    .checkboxPon label {
                        margin: 0.8rem 0 0.6rem 0;
                    }
                }

                .swiper-pagination-container {
                    margin: 1rem auto 0 auto;
                }

                .contract-usp {
                    background-color: #327cac;
                    padding: 1rem 3.2rem;
                }
            }
        }
    }

    .c-registration-form__action-buttons {
        justify-content: end;
    }

    .step-12.footer-buttons {
        display: flex;
        width: 100%;

        a {
            font-size: 1.4rem;
        }
    }
}

.swiper-wrapper {
    gap: 0.5rem;
}

.mail-arrow-container {
    display: none;

    &.fadeIn {
        display: block;
        animation: fadeIn ease 3s;

        .pijltje {
            display: block;
            margin-left: 6rem;
            font-size: inherit;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                right: -5px;
                top: 20px;
                width: 22px;
                height: 16px;
                background-image: url("/theme/vrijopnaam/svg/pijltje.svg");
                background-repeat: no-repeat;
                animation: fadeIn ease 3s;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.step-6 .kassabon .welkomstKortingContract, .welkomstKortingContractNo {
    text-align: center;
    padding: 0.5rem;
    font-size: 14px;
    margin-top: 2rem;
    margin-bottom: 0;

    &.welkomstKortingContract {
        background-color: #c5e8ff;
        flex-wrap: wrap;
    }
}


.vinkjeKassabon {
    vertical-align: top;
}

.kassabonButton {
    text-align: center;
    background-color: #fbdc2d;
}

/* Formulier adres gegevens */
.step-7 {
    #row_RegistrationFormStep7_bedrijfsnaam, #row_RegistrationFormStep7_kvk {
        display: none;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="date"] {
        padding: 1rem;
        background-color: #ffffff;
        padding-inline-end: 4ch;
        max-width: 230px;
        font-weight: 300;
    }

    #RegistrationFormStep7_tussenvoegsel {
        max-width: 130px;
    }

    span {
        margin-bottom: 1.5rem;
        display: block;
    }

    .breakout {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-medium) {
            flex-direction: row;
            flex-wrap: wrap;

            div {
                margin-right: 1.5rem;
            }

        }

    }

    #RegistrationFormStep7_voornaam,
    #RegistrationFormStep7_achternaam,
    #RegistrationFormStep7_email,
    #RegistrationFormStep7_telefoon,
    #RegistrationFormStep7_bedrijfsnaam,
    #RegistrationFormStep7_kvk {
        &:valid {
            background-image: url(/theme/vrijopnaam/svg/vinkje-groen.svg);
            background-position: 92% center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }

    input.non-empty-valid {
        background-image: url(/theme/vrijopnaam/svg/vinkje-groen.svg);
        background-position: 92% center;
        background-size: 20px;
        background-repeat: no-repeat;
    }

    #RegistrationFormStep7_email {
        max-width: 300px;
    }
}

.c-registration-form-container__step-14 {

    & .c-step__intro-text {
        display: none;
    }

    .email-text, .phone-text {
        display: none;
        margin: -1rem 0 2rem;
        font-size: 1.4rem;
    }
}

input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 120px;
}

input::-webkit-datetime-edit {
    position: relative;
    left: 0;
}

input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 0;
}


.step-8 {
    div {
        display: flex;
        flex-direction: column;
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="date"] {
        padding: 1rem;
        background-color: #ffffff;
    }

    .breakout {
        display: flex;
        flex-direction: column;

        @media (min-width: $breakpoint-medium) {
            flex-direction: row;
            flex-wrap: wrap;

            div {
                margin-right: 1.5rem;
            }
        }
    }

    #RegistrationFormStep8_leverpostcode {
        text-transform: uppercase;
    }

    #RegistrationFormStep8_leverpostcode,
    #RegistrationFormStep8_leverhuisnummer,
    #RegistrationFormStep8_leverstraatnaam,
    #RegistrationFormStep8_leverplaats, {
        &:valid {
            background-image: url(/theme/vrijopnaam/svg/vinkje-groen.svg);
            background-position: 92% center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }

    .errorPostcode {
        display: none;
        width: fit-content;
        margin-bottom: 1rem;
    }

    .errorPostcodeText {
        background-color: #FAD728;
        border-left: solid #FF7301 2px;
        padding: 10px 20px 10px 6px;
        font-size: 1.5rem;
        color: #323537;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.step-9 {
    #row_RegistrationFormStep9_betaalpostcode, #row_RegistrationFormStep9_betaalhuisnummer,
    #row_RegistrationFormStep9_betaalhuisnummertoevoeging, #row_RegistrationFormStep9_betaalstraatnaam,
    #row_RegistrationFormStep9_betaalplaats {
        display: none;
    }

    div {
        display: flex;
        flex-direction: column;
    }

    input[type="text"],
    input[type="number"],
    input[type="tel"],
    input[type="email"],
    input[type="date"] {
        padding: 1rem;
        background-color: #ffffff;
        width: 230px;
    }

    #RegistrationFormStep9_iban, #RegistrationFormStep9_betaalpostcode {
        display: block;
        width: 100%;
        text-transform: uppercase;

        @media (min-width: $breakpoint-medium) {
            width: 25ch
        }

    }

    #RegistrationFormStep9_tennamevan {
        display: block;
        width: 100%;

        @media (min-width: $breakpoint-medium) {
            width: 25ch
        }

    }

    #RegistrationFormStep9_iban,
    #RegistrationFormStep9_tennamevan {
        padding-inline-end: 3.7ch;

        &:valid {
            background-image: url(/theme/vrijopnaam/svg/vinkje-groen.svg);
            background-position: 92% center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }

    .errorDate {
        display: none;
    }

}

@media (min-width: $breakpoint-medium) {
    #RegistrationFormStep9_betaalstraatnaam,
    #RegistrationFormStep8_leverstraatnaam {
        width: 40ch;
    }
}

.c-registration-form-container__step-16 {

    .iban-text {
        display: none;
        margin: -1rem 0 2rem;
        font-size: 1.4rem;
    }
}

.step-10 {
    display: flex;
    flex-wrap: wrap;

    .form-group {
        .col-sm-2 {
            display: none;
        }
    }

    .form-group.row {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: $breakpoint-medium) {
        button[type="submit"] {
            width: 100%;
        }
    }

}

.step10a,
.step-10b {
    input[type="date"] {
        margin-left: 1rem;
        padding: 1rem;
    }
}

#RegistrationFormStep10B_currentContractEndDate {
    display: block;
}

.c-registration-form-container__step-19 {

    .c-step__intro-text {
        display: none;
    }
}

.step-11 {

    .checkbox {

        display: inline-flex;

        label {
            font-weight: bold;
        }

    }

    label span.checkbox-custom {
        margin-left: 0;
    }

    label div {
        margin-left: 3.5rem;
    }

    .betaalverplichting {
        font-size: 1.5rem;
        margin-block-end: 1rem;
        margin-block-start: 2rem;
    }

    .opmerking {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        textarea {
            width: 100%;
            margin-bottom: 2rem;
            min-height: 100px;
            padding: 1rem;

            @media (min-width: $breakpoint-medium) {
                max-width: 80%;
            }
        }

    }

    .bijschrift {
        max-width: 300px;
        margin: -1rem auto 0 2rem;

        .english {
            width: auto;
        }
    }

}

#RegistrationFormStep10C_ingangsdatum,
#RegistrationFormStep10B_currentContractEndDate,
#RegistrationFormStep7_geboortedatum {
    text-transform: uppercase;
    color: #636C72;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #636C72;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: #636C72;
    }

    &:-ms-input-placeholder { /* IE 10+ */
        color: #636C72;
    }

    &:-moz-placeholder { /* Firefox 18- */
        color: #636C72;
    }
}

input[type=date] {
    min-width: 210px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.c-registration-form-block {
    h2 {
        font-size: 2.2rem;
        font-weight: 700;
        color: $kleur-almost-black;

        @media (min-width: $breakpoint-large) {
            font-size: 3.3rem;
        }
    }

    .c-registration-form-block_wrapper {
        background-color: $kleur-blue-light-2;
        @media (min-width: $breakpoint-medium) {
            height: 600px;
        }

        h2 {
            color: $kleur-almost-black;
            text-transform: none;
            margin-bottom: 0;
            font-family: $base-font-family;
            @media (min-width: $breakpoint-large) {
                font-size: 2.8rem;
            }
        }

        .inschatten {
            font-size: 1.4rem;
        }

        div[class^="step-"] .form-group {
            margin-right: 0;
        }

        form[name="zelf-opwekken-registration-form"] {
            @media (min-width: $breakpoint-xlarge) {
                width: 100vw;
                position: relative;
                left: 50%;
                right: 50%;
                margin-left: -50vw;
                margin-right: -50vw;

                .c-registration-form__fields-container {
                    margin-left: 37.5%;

                    & + div.row {
                        margin-left: 41.5%;
                    }
                }

                h2 {
                    margin-left: 18.3%;
                }
            }

            .zelfOpwekkenInleg {
                margin-right: -1rem;
            }

            .details-container .details {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
            }
        }
    }

    .columns-2 {
        margin-left: 0;
        margin-right: 0;

        > div:first-child {
            margin-bottom: 4rem;
        }
    }

    legend {
        background-color: $kleur-blue;
        width: 100vw;
        max-width: none;
        font-size: -24px;
        margin-left: -24px;
        padding-left: 24px !important;
        @media (min-width: $breakpoint-xlarge) {
            min-width: 105vw;
        }
    }

    .c-step__heading.p-m.mb-xxl {
        margin-bottom: 40px !important;
    }

    .details-wrapper.zelfopwekken {
        position: relative;
        top: -10px;
        flex: 0 0 45%;
        max-width: 55%;
        margin-left: auto;
        margin-right: auto;

        @media screen and (max-width: $breakpoint-medium) {
            flex: unset;
            max-width: unset;
        }
    }

    .details-wrapper {
        position: relative;
    }

    .details-container {
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 8px;
        border: 1px solid #dddede;
        border-bottom: 0;
        min-width: 288px;
        max-width: 600px;
        height: 100%;

        .details {
            padding: 32px 24px 0;

            > div, div.contractKeuze-modal.content {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;

                &:nth-child(odd) {
                    border-bottom: 1px solid $kleur-grey-light-3;
                    padding-bottom: 8px;
                }

                &:nth-child(even) {
                    margin-bottom: 2rem;
                }
            }

            p, span {
                font-size: 1.4rem;

                @media (min-width: $breakpoint-medium) {
                    font-size: 1.8rem;
                }
            }

            a {
                font-size: 1.4rem;
            }

            .header {
                p {
                    margin-bottom: 0;
                }
            }

            .subheader {
                p {
                    color: #636363;
                }
            }

            .button_container {
                border-bottom: none !important;

                button#nextButton {
                    margin-top: 2rem;

                    @media (min-width: $breakpoint-medium) {
                        margin-top: 66px;
                    }
                }
            }
        }
    }

    .button_container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 4rem;

        a {
            font-size: 1.4rem;
            align-self: center;
        }
    }

    .link-container {
        margin-top: 1rem;
        width: 100%;
    }

    .footer {
        background-color: $kleur-light-grey-v6;
        padding: 1.6rem .8rem;
        border: 1px solid #f2f2f2;
        //margin-bottom: -2.4rem;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        display: flex;

        p {
            margin-bottom: 0;
            align-self: center;
            width: 75%;
            margin-left: 8px;

            @media (min-width: $breakpoint-medium) {
                font-size: 1.8rem;
            }
        }

        .tree-icon {
            width: 69px;
            height: 67px;
            position: relative;
            bottom: -17px;
            background-image: url(/theme/vrijopnaam/svg/tree-white.svg);
            background-repeat: no-repeat;
        }
    }


}


.popover {
    padding: 1.6rem;
    margin-right: 2rem;

    .popover-header,
    .popover-body {
        padding: 0;
    }
}

.c-newsletter-form {
    input {
        height: 40px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .btn-wrapper {
        border-top-left-radius: 8px;
        z-index: 3;
        position: relative;
        left: -16px;
        margin-right: -16px;

        button {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
        }
    }
}


/** Checkbox custom */

.step-3,
.step-6a,
.step-7,
.step-8,
.step-9,
.step10b,
.step-11 {

    .checkbox-label {
        display: inline-block;
        position: relative;
        cursor: pointer;

        label {
            margin-left: 3.5rem;
            font-weight: normal;
        }

    }

    input[type="checkbox"], {
        &:focus:checked ~ .checkbox-custom,
        &:focus ~ .checkbox-custom {
            border-color: $kleur-almost-black;
            border-width: 2px;
        }
    }

    .checkbox-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkbox-label .checkbox-custom {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 24px;
        width: 24px;
        background-color: white;
        border-radius: 4px;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        border: 1px solid #000000;
    }


    .checkbox-label input:checked ~ .checkbox-custom {
        background-color: #fff;
        border-radius: 4px;
        -webkit-transform: rotate(0deg) scale(1);
        -ms-transform: rotate(0deg) scale(1);
        transform: rotate(0deg) scale(1);
        opacity: 1;
        border: 1px solid #000000;
    }


    .checkbox-label .checkbox-custom::after {
        position: absolute;
        content: "";
        left: 12px;
        top: 12px;
        height: 0px;
        width: 0px;
        border-radius: 4px;
        border: solid #009BFF;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(0deg) scale(0);
        -ms-transform: rotate(0deg) scale(0);
        transform: rotate(0deg) scale(0);
        opacity: 1;
        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
    }


    .checkbox-label input:checked ~ .checkbox-custom::after {
        -webkit-transform: rotate(45deg) scale(1);
        -ms-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        opacity: 1;
        left: 8px;
        top: 3px;
        width: 6px;
        height: 12px;
        border-width: 0 2px 2px 0;
        background-color: transparent;
        border-radius: 0;
    }

}

.step-6a,
.step-8,
.step-10a {
    label.checkbox-label {
        margin-bottom: 0;
    }

    .checkbox-label-info {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}


.progress--complete {
    left: -16px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: calc(100% + 16px);
    @media (min-width: $breakpoint-medium) {
        //margin-left: -2.2rem;
    }
    @media (min-width: $breakpoint-large) {
        margin-left: -3.2rem;
        width: calc(100% + 48px);
    }

    .textual {
        background-image: url(/theme/vrijopnaam/svg/vinkje-black.svg);
        background-size: 16px 12px;
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 8px;
        @media (min-width: $breakpoint-medium) {
            background-position-x: 1.6rem;
        }

        .left {
            margin-left: 3.2rem;
            @media (min-width: $breakpoint-medium) {
                margin-left: 6.4rem;
            }
            @media (min-width: $breakpoint-large) {
                margin-left: 6.4rem;
            }
        }

        .right p {
            padding-right: 3rem;
        }

        .step-link {
            cursor: pointer;
            position: absolute;
            right: 0;
            display: flex !important;
            align-items: center;
            height: 100%;
            min-width: 16px;
            background-image: url(/theme/vrijopnaam/svg/pencil.svg), linear-gradient(to left, rgba(109, 206, 245, 1), rgba(109, 206, 245, 0.8));
            background-repeat: no-repeat;
            background-position: center right;

        }
    }
}

.popover-body .popover-close,
.modal-close {
    background-image: url(/theme/vrijopnaam/svg/close.svg);
    background-repeat: no-repeat;
    background-position-x: right;
    width: 16px;
    height: 16px;
    float: right;
    cursor: pointer;
    opacity: 1;
}

.popover-close:hover {
    opacity: .75;
}

#usage {

    #RegistrationFormStep3AB_stroomJaarVerbruikNormaal,
    #RegistrationFormStep3AB_stroomJaarVerbruikDal,
    #RegistrationFormStep3AB_stroomJaarVerbruikEnkel {
        background-image: url(/theme/vrijopnaam/images/kwh.png);
        background-position: 92% center;
        background-repeat: no-repeat;
        width: 228px;
    }

}

/** prijsopbouw */


table#toonprijsopbouw {

    #aantalPonKwh,
    #opwekEpTotaal {
        font-size: 15px;
    }

}

#RegistrationFormStep8_woonbestemming + label,
#RegistrationFormStep9_anderPostadres + label,
#RegistrationFormStep10C_ingangsdatumCheck + label {
    font-weight: normal;
}

.step-11 .checkbox-label label {
    font-weight: 300;
    max-width: 50ch;
}


@media (max-width: $breakpoint-medium) {
    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="number"] {
        width: 100%;
        max-width: 100%;
    }
}

// reken module

.c-registration-form-container__step-12 #rekenPonContainer .reken--overzicht-table {
    margin: 6px 10px 10px;
    max-width: 866px;
}

#rekenPonContainer {

    .details-wrapper {
        @media (min-width: $breakpoint-large) {
            width: 100%;
        }
    }

    .reken--overzicht-table {
        margin-right: 1.6rem;
    }

    #zelfOpwekkenInleg {
        margin-right: -1rem;
    }

}

#zelfOpwekkenWinst {
    font-weight: 700;
}


// Input Fonts and States

input[type=text],
input[type=email],
input[type=date],
input[type=number],
input[type=tel],
select {

    font-weight: $font-weight-light;
    height: 40px;

    &:hover {
        border-color: $kleur-almost-black;
    }

    &:focus,
    &:active {
        outline: 0;
        box-shadow: 0px 0px 0px 2px $kleur-almost-black !important;
        border-color: $kleur-almost-black;
        color: $kleur-almost-black;
    }

    &:disabled {
        border-color: $kleur-grey-light;
        color: $kleur-grey-light;
    }

    &:required {
        // This one's for Firefox! - https://github.com/react-toolbox/react-toolbox/issues/1639
        box-shadow: none;
    }
}

textarea {
    &:focus,
    &:active {
        outline: 0;
        box-shadow: 0px 0px 0px 0px $kleur-almost-black !important;
        border-color: $kleur-almost-black;
        color: $kleur-almost-black;
    }
}


input[type=text],
input[type=email],
input[type=number] {
    &:not(:placeholder-shown):invalid {
        // Don't show invalid, if we're showing the placeholder - https://stackoverflow.com/questions/7920742/delay-html5-invalid-pseudo-class-until-the-first-event
        color: $kleur-almost-black;
        box-shadow: 0px 0px 0px 2px $kleur-error !important;
        border-color: $kleur-error;
    }
}

input[type=range] {

    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */


    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    &::-ms-track {
        width: 100%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border: 1px solid #393c3f;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #393c3f;
        cursor: pointer;
        margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
    }

    /* All the same stuff for Firefox */
    &::-moz-range-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #393c3f;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #393c3f;
        cursor: pointer;
    }

    /* All the same stuff for IE */
    &::-ms-thumb {
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        border: 1px solid #393c3f;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background: #393c3f;
        cursor: pointer;
    }

    /* Track */

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: #327cac;
        border-radius: 1.3px;
        //margin-top: 8px;
    }

    &:focus::-webkit-slider-runnable-track {
        background: #327cac;
    }

    &::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: #327cac;
        border-radius: 1.3px;
    }

    &::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: #327cac;
        border-radius: 2.6px;
    }

    &:focus::-ms-fill-lower {
        background: #327cac;
    }

    &::-ms-fill-upper {
        background: #327cac;
        border-radius: 2.6px;
    }

    &:focus::-ms-fill-upper {
        background: #327cac;
    }

}

.step-4a {

    input[type=range] {

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid #393c3f;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: #393c3f;
            cursor: pointer;
            //margin-top: 10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
        }

        &::-webkit-slider-runnable-track {
            width: 100%;
            height: 5px;
            cursor: pointer;
            background: #327cac;
            border-radius: 1.3px;
            margin-top: 8px;
        }

    }

}

// Scrolling page fix

.row.wrapper {
    margin-bottom: 0;
    overflow-y: auto;
}

/*
body,
html{
    overflow: hidden;
    margin: 0;
    padding: 0;
    }
*/

html.aanmeld-wrapper {
    overflow: hidden;
    margin: 0;
    padding: 0;
    background-color: #6dcef5;

    body {
        overflow: hidden;
        margin: 0;
        padding: 0;
    }
}

// dit is de zoek button op Slimme Vragen

#button-addon2 {
    min-width: unset;
}

// Smooth scrolling behaviour

html {
    scroll-behavior: smooth;
}

// Progress bar overwrite

.progress {

    background-color: #EAF6FE;

}

// Custom css for clickable checkbox label

.checkbox-label > span:first-of-type {
    margin-left: 3.5rem;
    font-weight: normal;
}

// Boltforms overrides
body .boltforms-feedback.success {
    border: none;
    border-left: 2px solid $kleur-blue-wcag;
    border-radius-top-right: 0.8rem;
    border-radius-bottom-right: 0.8rem;
    background-color: $kleur-blue;
    color: $kleur-almost-black;
}

body .boltforms-feedback.error {
    background-color: #FAD728;
    border-left: solid #FF7301 2px;
    padding: 10px 20px 10px 6px;
    font-size: 1.5rem;
    color: #323537;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-width: fit-content;
}

body form.boltforms label.required::after {
    content: '';
}

// zelfopwekken rekentool

span#ZelfOpwekken_inschatten.required, span#ZelfOpwekken_verbruik.required {
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 25px;
    font-size: 14px;
    display: inline-block;
}

.checkbox-label.step-3.inschatten span.required {
    margin-left: 0;
    font-size: 14px;
}

.checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 1px solid #000000;
}

.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #fff;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 1px solid #000000;
}

.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

// error datum

.errorDate {
    background-color: #FAD728;
    border-left: solid #FF7301 2px;
    padding: 10px 20px 10px 6px;
    font-size: 1.5rem;
    color: #323537;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: fit-content;
    margin-block-end: 1rem;

    span.errorDatumText, span.maxFortyFiveDays, span.maxNinetyDays {
        margin: 0;
    }
}

.ytp-impression-link {
    display: none;
}

// This is the external link svg append to <a> tag in aanmelden
#external-link {
    margin-left: 0.6rem;
    width: 12px;
    border-radius: 0;
}

// Offertemail edits

#mailMeModal #offerteSubmitButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 1rem;
}

#quotation_email {
    min-width: 250px;
}

#registration-wrapper.row {
    margin-right: -1.61rem;
}
