// Colors
$kleur-white: #ffffff;
$kleur-almost-black: #393c3f;
$kleur-grey: #636363;
$kleur-grey-dark: $kleur-almost-black;
$kleur-grey-hover: #212324;
$kleur-grey-light: #808a90;
$kleur-grey-light-2: #e8e5e5;
$kleur-grey-light-3: #dddede;
$kleur-grey-light-4: #efefef;
$kleur-grey-light-5: #fbfbfb;
$kleur-grey-light-6: #f8f8f8;
$kleur-light-grey: #eaeaea;
$kleur-light-grey-v6: #f2f2f2;
$kleur-orange: #fbdc2d;
$kleur-orange-dark: #f29100;
$kleur-orange-wcag: #776917;
$kleur-orange-light: #fbd280;
$kleur-orange-background: rgba(251, 220, 45, 0.2);
$kleur-error: #ff7e00;
$kleur-blue-wcag: #327cac;
$kleur-blue-dark: #55bde6;
$kleur-blue: #6dcef5;
$kleur-blue-light: #eaf6fe;
$kleur-blue-light-2: #c5e8ff;
$kleur-green: #6dd155;
$kleur-green-hover: #4fbf34;
$kleur-green-wcag: #338724;

$rgba-kleur-almost-black: rgba(57, 60, 63, 0.2);

// Fonts
$base-font-family: museo, sans-serif;
$base-header-font-family: montserrat, sans-serif;
$base-font-color: $kleur-almost-black;
$base-header-font-color: $kleur-almost-black;

$font-size-base: 0.625rem; // 10px on default browser settings

$font-weight-black: 900;
$font-weight-bold: bold;
$font-weight-medium: 500;
$font-weight-light: 300;

// h1 general
$h1-font-size-large: 5.5rem; // 55px
$h1-font-size-medium: 4rem; // 40px
$h1-font-size-small: 3rem; // 30px
$h1-font-weight: 900;

// h2
$h2-font-size-large: 3.3rem; // 33px
$h2-font-size-medium: 2.8rem; // 28px
$h2-font-weight: bold;

// h3
$h3-font-size: 2.8rem; // 28px
$h3-font-weight: bold;

// h4
$h4-font-size: 2.2rem; // 22px
$h4-font-weight: bold;

// Breakpoints:
$breakpoint-small: 320px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1430px;
$breakpoint-xxlarge: 1600px;

// Buttons
$btn-primary-color: $kleur-green;
$btn-secondary-color: $kleur-almost-black;
$btn-blue-color: $kleur-blue-wcag;
$btn-light-blue-color: $kleur-blue-light;
$btn-primary-text-color: $kleur-almost-black;
$btn-text-color-white: $kleur-white;
$btn-text-orange: $kleur-orange;
$btn-text-blue: $kleur-blue;
