.chevron-left {
  transform: rotate(180deg);
}

.fill-blue {
  fill: $kleur-blue-wcag;
}

.icon-tree {
  display: inline-block;
  width: 49px;
  height: 49px;
  background-image: url(/theme/vrijopnaam/svg/tree.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

.icon-pig {
  display: inline-block;
  width: 49px;
  height: 49px;
  background-image: url(/theme/vrijopnaam/svg/zonneparken/icoon_varken_zeshoek.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

.icon-sunbed {
  display: inline-block;
  width: 49px;
  height: 49px;
  background-image: url(/theme/vrijopnaam/svg/chair.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

.icon-euro {
  display: inline-block;
  width: 49px;
  height: 49px;
  background-image: url(/theme/vrijopnaam/svg/euro.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
 }

.icon-phone {
  display: inline-block;
  width: 49px;
  height: 49px;
  background-image: url(/theme/vrijopnaam/svg/phone.svg);
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
}

.icon-facebook {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(/theme/vrijopnaam/svg/fb.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-twitter {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(/theme/vrijopnaam/svg/tw.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-youtube {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(/theme/vrijopnaam/svg/yt.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.icon-instagram {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url(/theme/vrijopnaam/svg/instagram-icon-white.svg);
  background-repeat: no-repeat;
  background-size: contain;
}

.info-icon {
  display: inline-block;
  vertical-align: text-top;
  min-width: 16px;
  min-height: 16px;
  background-image: url(/theme/vrijopnaam/svg/itje.svg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 8px;
  cursor: pointer;
}

