#prijsOpbouw {

    .modal-body {
        padding: 0 0.8rem;
    }

    .kassabon-detail {

        summary, div, span {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 2rem;
        }

        summary:before {
            content: "";
            display: inline-block;
            inline-size: 16px;
            block-size: 16px;
            background-image: url(/theme/vrijopnaam/svg/down-arrow-svgrepo-com.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transition: var(--bounce);
            -webkit-transition: var(--bounce);
            -moz-transition: var(--bounce);
            -ms-transition: var(--bounce);
            -o-transition: var(--bounce);
            padding-right: 3rem;
            transform: rotate(180deg);
        }

        details[open] summary:before {
            transform: none;
        }

        .flex {
            display: flex;
        }

        .blue {
            background-color: #c5e8ff;
        }

        .bedrag {
            font-weight: 700;
            padding-right: 0.8rem;
            margin-left: auto;
        }

        .light-blue {
            background-color: #eaf6fe;

            .bedrag {
                font-weight: 500;
            }
        }

        .spacing-left {
            padding-left: 3rem;
        }

        &.totaalBedragSection {

            .spacing-left {
                padding-left: 4rem;
            }

            .bedrag {
                padding-right: 1.6rem;
            }
        }

        .border-bottom-grey {
            border-bottom: 1px solid #dddede;
            padding: 1rem 0 1rem 0;
        }

        .border-bottom-white {
            border-bottom: 2px solid white;
            padding: 1rem 0 1rem 0;
        }

        &.totaalBedragSection {
            border-top: 2px solid black;
        }
    }

    .maandbedrag {
        background-color: $kleur-orange;
        text-align: center;
        font-weight: bold;
        padding: 0.6rem;
        margin-bottom: 0;

        .geschatteKostenMaand {
            font-size: 2.8rem;
            font-weight: bold;
            text-align: center;
            display: block;
            line-height: 3.2rem;
        }
    }
}
