.ui-datepicker {

  width: 230px;
  border: 1px solid black;
  border-radius: 8px;
  padding: 1rem;
  background-color: white;
  display: none;

  .ui-datepicker-header {
    position: relative;
    margin-block-end: 1rem;
    line-height: 3rem;

    span{
      margin: 0;
      line-height: 3rem;
    }
  }

  .ui-datepicker-next {
    position: absolute;
    right: 0;
  }

  .ui-corner-all {
    text-decoration: none;
    color: black;
  }

  .ui-icon {
    font-size: 3rem;
    cursor: pointer;
  }

  .ui-datepicker-title {
    line-height: 0;
  }

  .ui-datepicker-month, .ui-datepicker-year {
    position: absolute;
    top: 2px;
  }

  .ui-datepicker-month {
    left: 2.5rem;
  }

  .ui-datepicker-year {
    right: 2.5rem;
  }

  td {
    padding: 5px 0;
    text-align: center;
    font-size: 1.7rem;
    border-radius: 8px;
    width: 3rem;

    a {
      display: block;
    }

    span {
      margin: 0;
    }
  }

  .ui-datepicker-current-day {
    background-color: #0044cc;
  }

  .ui-state-active {
    color: white !important;
  }

  .ui-datepicker-month, .ui-datepicker-year {
    font-weight: bold;
  }

  .ui-datepicker-calendar {
    margin-block-start: 0.3rem;
    width: -webkit-fill-available;
    margin-bottom: 0;
  }

  th span {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  select.ui-datepicker-year, select.ui-datepicker-month {
    height: 3rem;
    border-radius: 8px;
    padding: 0;
  }

  .ui-datepicker-today {
    background-color: #fde19a;
  }
}


.datepicker-row {
  position: relative;
  width: fit-content;
}

img.ui-datepicker-trigger {
  width: 20px;
  position: absolute;
  top: 40px;
  left: 190px;
  border-radius: 0;
}

.step-7 .errorDatum {
  display: none;
}
