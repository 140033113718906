.process-status-wrapper {
  background-color: red;
  background: linear-gradient(to bottom, rgba(57, 60, 63, 0.25), rgba(57, 60, 63, 0.85) 50%);
  margin-top: -316px;

  @media screen and (min-width: 449px) and (max-width: 552px) {
    //margin-top: -140px;
  }

  @media screen and (min-width: 768px) {
    margin-top: -102px;
  }

  @media screen and (min-width: 968px) {
    margin-top: -64px;
  }

  .process-step {
    color: $kleur-white;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
    }
    .dot {
      height: 16px;
      width: 16px;
      border-radius: 50%;
    }
    .line {
      height: 3px;
      width: 24px;
      background: $kleur-white;
      display: block;
      top: 50%;
    }
    @media screen and (max-width: 767px) {
      position: relative;
      margin-bottom: 1rem;
      width: 100%;
      .dot {
        margin-bottom: 1rem;
      }
      p {
        margin-bottom: 1rem;
      }
      .line {
        position: absolute;
        top: 100%;
        transform: rotate(90deg);
      }
    }
  }
  .process-step__finished {
    .dot {
      background-color: $kleur-orange;
    }
  }
  .process-step__current {
    .dot {
      background-image: url('/theme/vrijopnaam/svg/zonneparken_subsites/zon-icoon.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 40px;
      height: 40px;
    }
  }
  .process-step__pending {
    .dot {
      background-color: $kleur-white;
    }
  }
}

.newsletter-wrapper {
  background-color: $kleur-almost-black;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 768px) {
      gap: 2.4rem;
    }
    .input-wrapper {
      @media screen and (min-width: 1024px) {
        width: 460px;
      }
    }
  }
  p {
    color: $kleur-white;
    margin-bottom: 0;
  }
}

.project-block-container {
  .heading,
  .footer {
    font-weight: 700;
  }
  .cipher {
    font-size: 50px;
    font-weight: 700;
  }
}

#hoeRekenenJullie {
  p {
    color: $kleur-white;
  }
}

section.image-and-text-container {
  .text-wrapper {
    ul {
      padding-left: 1.6rem;
      li {
        margin-bottom: 2.4rem;
      }
    }
    h2.text-transform-none {
      text-transform: none;
      font-family: $base-font-family;
      font-size: 2.8rem;
    }
  }
  .c-video-2col_container {
    grid-template-rows: 64px auto 1fr 16px;
  }
}
.boltforms[name=contact_subsites] {
  .newsletter {
    display: flex;
    flex-direction: row-reverse;
    label {
      font-weight: 300;
      padding-left: 1.2rem;
      margin-bottom: 2rem;
    }
  }
  button {
    color: $kleur-orange;
  }
  .newsletter-row {
    .checkbox-label > span:first-of-type {
      font-weight: 300;
    }
  }
}

.call-to-action-btn-wrapper {
  a.btn-black {
    color: $kleur-orange;
    svg path {
      fill: $kleur-orange;
    }
  }
}