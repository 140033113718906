.suggestion-container {
  position: relative;
  background: linear-gradient(to bottom, $kleur-white, $kleur-orange 33%);
  margin-top: -132px;
  padding-top: 171px !important;
  h2 {
    font-family: $base-font-family;
    font-size: 2.8rem;
    text-transform: none;
    display: flex;
    align-items: center;
    &:before {
      content: "";
      background-image: url(/theme/vrijopnaam/svg/cooperatie/idee-icoon.svg);
      height: 68px;
      min-width: 62px;
      display: inline-block;
      margin-right: 0.8rem;
      background-repeat: no-repeat;
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    p {
      font-size: 1.8rem;
      margin-bottom: 0;
    }
    a {
      color: $kleur-white;
      text-decoration: underline;
      font-size: 1.8rem;
    }
  }
}

.voor-section--cooperatie {
  .header-image-blocks {
    top: -80px;
    margin-top: 19.2rem;
    .header-block-item:nth-child(1) {
      .block-item_wrapper {
        background-image: url("/theme/vrijopnaam/svg/cooperatie/zon-icoon.svg");
      }
    }
    .header-block-item:nth-child(2) {
      .block-item_wrapper {
        background-image: url("/theme/vrijopnaam/svg/cooperatie/pon-icoon.svg");
      }
    }
  }
}