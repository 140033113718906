.jumbotron-fluid {
  .textual {
    padding-top: 16px;
    margin: 0;
    min-height: 199px;
    position: relative;
    @media (min-width: $breakpoint-medium) {
      padding-top: 30px;
    }

    + div {
      padding: 80px 0 64px;
    }
  }

  &.header-text {
    .textual {
      display: block;

      .title {
        margin-bottom: 0;
        padding: 64px 0;
      }

      .c-search-form__container {
        margin-top: -3.2rem;
      }
    }
  }

  h1 {
    margin-bottom: 0;
  }

  &[style*="background-image"] .textual {
    min-height: 755px;

    @media (min-width: $breakpoint-medium) {
      min-height: 589px;
    }

    @media (min-width: $breakpoint-large) {
      min-height: 534px;
    }
  }


}

.header-image {
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 428px;
  position: relative;
}

.header-image-text[style*="background-image"] {
  @media (max-width: $breakpoint-medium) {
    min-height: 0;
  }
  .hero-container {

    .text-white {
      margin-bottom: 1.6rem;
      padding-right: 1.6rem;
    }

    .social-proof {
      padding: 7px 8px 7px;
      border-radius: 8px;
      background-color: rgba(57, 59, 63, 0.5);
      color: white;
      font-weight: bold;
      width: fit-content;

      @media screen and (max-width: $breakpoint-large) {
        font-size: 1.4rem;
      }

      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
      }
    }

    @media (max-width: $breakpoint-medium) {
      min-height: 0;
      padding-top: 18px;
    }
    @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
      padding-top: 6.4rem
    }
    @media (min-width: $breakpoint-large) {
      padding-top: 6.4rem;
    }

    .c-registration-block {
      @media (max-width: $breakpoint-medium) {
        position: relative;
      }
    }
  }
}

div.jumbotron-fluid.pt-sm-xxl.pt-xxxl.header-image {
  display: none;

  @media (min-width: $breakpoint-medium) {
    display: block;
  }
}

div.container.px-sm-m.px-lg-l.mb-xl {
  padding-top: 57px;

  @media (min-width: $breakpoint-medium) {
    padding-top: unset;
  }
}

.c-news-detail div.container.px-sm-m.px-lg-l.mb-xl {
  padding-top: 0;
}
