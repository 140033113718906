.step-6 {

    .swiper-container {
        --swiper-navigation-size: 15px;
        position: relative;
    }

    .swiper-pagination {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15px;
        position: unset;
    }

    .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);;
    }

    .swiper-pagination-bullet {
        width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
        height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
        opacity: 1;
        background: #eaf6fe;

        &.swiper-pagination-bullet-active {
            background: #393c3f;
        }
    }

    .swiper-pagination-container {
        align-items: center;
        justify-content: space-between;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        margin-block-start: 2rem;
    }

    .swiper-button-next, .swiper-button-prev {
        color: #393c3f;
        font-weight: bold;
        position: unset;
        margin-top: 0;
    }

    .swiper-button-prev {
    }
}