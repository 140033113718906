.zonneparken {
    .main-header {
        margin-bottom: 13rem !important;
    }

    .header-image-text[style*="background-image"] {
        max-height: 475px;
        @media (min-width: $breakpoint-xlarge) {
            max-height: 445px;
        }
    }

    .hexagons-overlay {
        margin-top: -21rem;
        @media (min-width: $breakpoint-xlarge) {
            margin-top: -28rem;
        }
    }

    .col2-text {
        ul {
            list-style-position: inside;
            padding-left: 0;
        }
    }

    .col2-text-footer {
        p {
            font-weight: $font-weight-bold;
        }
    }

    img {
        @media (min-width: $breakpoint-small) {
            border-radius: 0;
        }
        @media (min-width: $breakpoint-medium) {
            border-radius: 0.8rem;
        }
    }

    .custom-list {
        .custom_list__item {
            display: flex;
            flex-wrap: wrap;
            @media (min-width: $breakpoint-medium) {
                flex-wrap: nowrap;
            }

            &:last-child {
                .list_item_textual p:last-child {
                    margin-bottom: 0;
                }
            }

            .custom_list__group {
                margin-bottom: 2.4rem;
                display: flex;
                @media (min-width: $breakpoint-medium) {
                    flex: 25% 1;
                }
            }
        }

        .list_item_numerical {
            margin-right: 2.4rem;
            @media (min-width: $breakpoint-medium) {
                margin-right: 0;
            }

            p {
                margin-bottom: 0;
                font-size: 2.8rem;
                font-weight: $font-weight-bold;
            }
        }

        .list_item_visual {
            @media (min-width: $breakpoint-medium) {
                flex: 0% 1;
                text-align: center;
            }
        }

        .list_item_textual {
            @media (min-width: $breakpoint-medium) {
                flex: 75% 1;
                flex: 75% 1;
            }

            p {
                margin-bottom: 2.4rem;
            }
        }
    }

    .bg-hexagon {
        background-image: url(/theme/vrijopnaam/svg/hexagon-border-white.svg);
        padding-left: 4.6rem;
        padding-right: 4.7rem;
        @media (min-width: $breakpoint-medium) {
            width: 228px;
            height: 263px;
            padding-left: 1.6rem;
            padding-right: 1.7rem;
        }
        @media (min-width: $breakpoint-xlarge) {
            width: 330px;
            height: 380px;
            padding-left: 6.8rem;
            padding-right: 6.7rem;
        }

        h3 {
            font-size: 2.2rem;
            margin-bottom: 0.16rem;
            flex: 1 0 37.3%;
            display: flex;
            align-items: flex-end;
            @media (min-width: $breakpoint-medium) {
                flex: 1 0 50%;
            }
        }

        p {
            flex: 1 0 30.3%;
            font-size: 1.4rem;
            @media (min-width: $breakpoint-medium) {
                flex: 1 0 33%;
            }
            @media (min-width: $breakpoint-xlarge) {
                flex: 1 0 23%;
                font-size: 1.8rem;
            }

            &:last-child {
                display: flex;
                align-items: flex-end;
            }
        }
    }

    .contact-card {
        img {
            max-width: 88px;
            height: 88px;
            border-radius: 50%;
        }
    }

    .media-contact {
        img {
            object-fit: cover;
            border-radius: .8rem;
        }
    }

    .form-bg-image {
        height: 199px;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .boltforms[name=contact],
    .boltforms[name=cooperatie_suggestion],
    .boltforms[name=contact_subsites] {
        label {
            margin-bottom: 0.8rem;
            font-weight: $font-weight-bold;
        }

        label[for="contact_domain_province_twitter_recipient"],
        input#contact_domain_province_twitter_recipient {
            visibility: hidden;
        }

        #contact,
        #cooperatie_suggestion,
        #contact_subsites {
            @media (min-width: $breakpoint-medium) {
                label {
                    display: block;
                }
                .name-row,
                .last-name-row {
                    width: 100%;
                    float: left;

                    input {
                        width: 66.66%;
                    }
                }
                .email-row {
                    width: 100%;

                    input {
                        width: 85%;
                    }
                }
                .comments-row {
                    width: 100%;
                }
            }
            @media (min-width: $breakpoint-large) {
                .name-row,
                .last-name-row {
                    width: 50%;

                    input {
                        width: 95%;
                    }
                }
                .email-row {
                    width: 66.6%;

                    input {
                        width: 100%;
                    }
                }
                .comments-row {
                    width: 80%;
                }
                .phone-row {
                    width: 47.5%;

                    input {
                        width: 100%;
                    }
                }
            }
        }

        textarea {
            resize: none;
            width: 100%;
            border-radius: 0.8rem;
            border: 1px solid $kleur-almost-black;
            margin-bottom: 2rem;

            &:focus {
                outline: 0;
                box-shadow: 0px 0px 0px 2px #393c3f !important;
                border-color: #393c3f;
                color: #393c3f;
            }
        }
    }

    .procedure-wrapper {
        background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat 189px 27px/88px 40px,
        linear-gradient(0deg, #6dcef5 0%, #eaf6fe 100%);
        @media (min-width: $breakpoint-medium) {
            background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat 193px 23px/88px 40px,
            url(/theme/vrijopnaam/svg/zonneparken/vogels.svg) no-repeat calc(100% - 99px) 190px/68px 28px,
            linear-gradient(0deg, #6dcef5 0%, #eaf6fe 100%)
        }
        @media (min-width: $breakpoint-large) {
            background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat 193px 23px/88px 40px,
            url(/theme/vrijopnaam/images/pijl.svg) no-repeat center 100px,
            linear-gradient(0deg, #6dcef5 0%, #eaf6fe 100%);
        }
        @media (min-width: $breakpoint-xlarge) {
            background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat 223px 20px/88px 40px, url(/theme/vrijopnaam/images/pijl.svg) no-repeat center 100px,
            linear-gradient(0deg, #6dcef5 0%, #eaf6fe 100%)
        }
        position: relative;
        padding-top: 52px;

        &:before {
            content: "";
            width: 100%;
            height: 80px;
            background: url(/theme/vrijopnaam/images/zon.png) no-repeat center top;
            background-size: 80px;
            display: block;
            margin: 0 auto;
            position: absolute;
            top: -44px;
        }
    }

    .procedure-list {

        @media (min-width: $breakpoint-medium) {
            display: grid;
            grid-template-columns: 50%;
            column-gap: 64px;
        }

        @media (min-width: $breakpoint-large) {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 24px;
        }

        @media (min-width: $breakpoint-xlarge) {
            column-gap: 56px;
        }
    }

    .procedure-list__item {
        margin-bottom: 2.4rem;

        &:nth-child(1) {
            //background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat center center;
            //background-size: contain;
        }

        @media (min-width: $breakpoint-medium) {
            margin-bottom: 4rem;
            &:nth-child(odd) {
                grid-column: 1 / span 1;
            }
            &:nth-child(even) {
                grid-column: 2 / span 1;
            }
            &:nth-child(7) {
                background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat center/88px 40px,
                url(/theme/vrijopnaam/svg/zonneparken/vogels.svg) no-repeat right 100px/68px 28px
            }
            &:nth-child(9) {
                margin-top: -15rem;
            }
        }

        @media (min-width: $breakpoint-large) {
            &:nth-child(3n+1) {
                grid-column: 1 / span 1;
            }
            &:nth-child(3n+2) {
                grid-column: 3 / span 1;
            }
            &:nth-child(3n+3) {
                grid-column: 2 / span 1;
                margin-top: -28rem;
            }
            &:nth-child(3) {
                position: relative;

                &:before {
                    content: "";
                    background: url(/theme/vrijopnaam/svg/zonneparken/vogels.svg) no-repeat center/68px 28px;
                    width: 68px;
                    display: inline-block;
                    height: 28px;
                    position: absolute;
                    right: 0;
                    top: -28px;
                }
            }
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: -6rem;
            }
            &:nth-child(6) {
                margin-top: -22rem;
            }
            &:nth-child(7) {
                margin-top: -15rem;
                background: url(/theme/vrijopnaam/svg/zonneparken/wolk.svg) no-repeat 72px calc(100% - 44px)/88px 40px,
                url(/theme/vrijopnaam/svg/zonneparken/vogels.svg) no-repeat 229px calc(100% - 113px)/68px 28px
            }
            &:nth-child(8) {
                margin-top: -15rem;
            }
            &:nth-child(9) {
                margin-top: -18rem;
            }
        }

        @media (min-width: $breakpoint-xlarge) {
            &:nth-child(3) {
                margin-top: -24rem;
            }
            &:nth-child(4),
            &:nth-child(5) {
                margin-top: -10rem;
            }
            &:nth-child(6) {
                margin-top: -17rem;
            }
        }
    }

    .list-item__textual {
        padding-left: 0.8rem;

        p {
            font-size: 1.4rem;
            @media (min-width: $breakpoint-xlarge) {
                font-size: 1.8rem;
            }
        }

        margin-bottom: 1.6rem;
    }

    .list-item__heading {
        margin-bottom: 0.8rem;
        display: flex;

        span {
            font-size: 2.8rem;
            font-weight: $font-weight-bold;
            align-self: center;
            margin-right: 1.1rem;
        }

        h3 {
            font-size: 1.4rem;
            margin-bottom: 0;
            line-height: 1.4;
            @media (min-width: $breakpoint-xlarge) {
                font-size: 1.8rem;
            }
        }
    }

    .review-card {
        position: relative;
        //max-width: 350px;
        @media (min-width: $breakpoint-medium) {
            max-width: none;
        }
    }

    .review-card__textual {
        background: #eaf6fe;
        border-radius: 0.8rem;
        padding: 1.6rem;
        width: 85%;
    }

    .review-card__body {
        width: 75%;
        margin-bottom: 20px;

        p {
            font-size: 1.4rem;
            font-weight: $font-weight-light;
            @media (min-width: $breakpoint-xlarge) {
                font-size: 1.8rem;
            }
        }
    }

    .review-card__footer {
        width: 75% item__textual;
        @media (min-width: $breakpoint-medium) {
            width: 100%;
        }

        p {
            font-size: 1.4rem;
            font-weight: $font-weight-light;
            color: $kleur-blue-wcag;
            margin-bottom: 0;
            @media (min-width: $breakpoint-xlarge) {
                font-size: 1.8rem;
            }
        }
    }

    .review-card__visual {
        float: right;
        margin-right: -6.6rem;

        img {
            border-radius: 50%;
        }
    }

    .voor-section {
        margin-bottom: 190px !important;
        @media (min-width: $breakpoint-medium) {
            margin-bottom: 190px !important;
        }

        @media (max-width: 767px) {
            .row.textual {
                margin-bottom: 6.5rem;
            }
        }
    }

    .text-quote-container {
        .textual {
            @media (min-width: $breakpoint-medium) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h3 {
                font-size: 2.2rem;
            }
        }

        .quote-wrapper {
            background-image: url(/theme/vrijopnaam/svg/quote.svg);
            background-size: 73px 51px;
            background-repeat: no-repeat;

            @media (min-width: $breakpoint-medium) {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &.bg-black {
                p {
                    color: $kleur-white;
                }
            }

            .quote-text {
                font-size: 2.2rem;
                font-weight: $font-weight-bold;
                line-height: 30px;
            }

            .quote-author {
                font-weight: $font-weight-light;
                margin-bottom: 2.4rem;
            }
        }

        .quote-right {
            background-position: calc(100% - 40px) 24px;
            border-top-left-radius: 0.8rem;
            border-bottom-left-radius: 0.8rem;
            margin-bottom: 3.2rem;
            @media (min-width: $breakpoint-medium) {
                position: relative;
                right: 16px;
                margin-bottom: 0;
                border-radius: 0.8rem;
                background-position: calc(100% - 24px) 24px;
            }

            img {
                border-radius: 50%;
                width: 204px;
                height: 204px;
                position: relative;
                left: -75px;
                margin-top: 7.5rem;
                @media (min-width: $breakpoint-medium) {
                    position: relative;
                    left: -75px;
                    margin-left: calc(-100% + 218px);
                    margin-top: 7.5rem;
                }
            }
        }

        .quote-left {
            background-position: 40px 24px;
            border-top-right-radius: 0.8rem;
            border-bottom-right-radius: 0.8rem;
            text-align: right;
            @media (min-width: $breakpoint-medium) {
                background-position: 24px 24px;
                position: relative;
                left: 16px;
                border-radius: 0.8rem;
            }

            img {
                border-radius: 50%;
                width: 204px;
                height: 204px;
                position: relative;
                right: -75px;
                margin-top: 75px;
                @media (min-width: $breakpoint-medium) {
                    right: -75px;
                    margin-right: calc(-100% + 218px) !important;
                }
            }
        }
    }

    .view-project {
        .project-block-container {
            max-width: 75%;
            @media (max-width: 767px) {
                margin: 0 auto;
            }
            @media (min-width: $breakpoint-medium) {
                margin-top: 0;
                margin-right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media (min-width: $breakpoint-xlarge) {
                max-width: 235px;
            }

            .author {
                font-weight: $font-weight-light;
                color: $kleur-blue-wcag;
            }
        }

        .bg-quote {
            background-image: url(/theme/vrijopnaam/svg/quote-blue.svg);
            background-repeat: no-repeat;
        }

        .project-block-quote {
            p:first-child {
                font-weight: $font-weight-bold;
            }
        }
    }

    .project-block--numerical {
        p:first-child {
            font-weight: $font-weight-bold;
            font-size: 5rem;
            margin-bottom: 0;
        }

        p:last-child {
            font-weight: $font-weight-bold;
            margin-top: 2.4rem;
            margin-bottom: 0;
        }
    }

    .c-registration-form__fields-container {
        input[type="text"] {
            padding-left: 1rem;
            @media (min-width: $breakpoint-medium) and (max-width: 1023px) {
                width: 65%;
            }
        }

        input[type="email"] {
            padding: 1rem;
            @media (min-width: $breakpoint-medium) and (max-width: 1023px) {
                width: 85%;
            }
        }

        textarea {
            padding: 1rem;
        }
    }

    .image-left {
        .img-max-width {
            max-width: 310px;
        }
    }

    .phase-wrapper {
        position: absolute;
        background-color: $black;
        color: $kleur-white;
        width: calc(100% - 24px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        p {
            margin-bottom: 0;
            display: flex;
        }
    }
}

.phase-circle {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 8px;

    &--in_preparation {
        background-color: $kleur-white;
    }

    &--permit {
        background-color: $kleur-blue;
    }

    &--grid_connection {
        background-color: $kleur-green;
    }

    &--realization {
        background-color: $kleur-error;
    }

    &--in_use {
        background-color: $kleur-orange;
    }
}


.zonneparken {
    //.suggestion-container {
    //  .col-sm-12.col-md-4.col-lg-4.offset-lg-1.mb-l {
    //    @media (min-width: $breakpoint-large) {
    //      max-width: 312px;
    //    }
    //  }
    //}
    .boltforms[name=cooperatie_suggestion] {
        #cooperatie_suggestion {
            @media (min-width: $breakpoint-medium) {
                .name-row,
                .last-name-row {
                    input {
                        width: 100%;
                    }
                }
                .email-row {
                    input {
                        width: 100%;
                    }
                }
                .comments-row {
                    width: 100%;
                }
            }
            @media (min-width: $breakpoint-large) {
                .name-row,
                .last-name-row {
                    width: 100%;

                    input {
                        width: 100%;
                    }
                }
                .email-row {
                    width: 100%;
                }
                .comments-row {
                    width: 100%;
                }
                .phone-row {
                    width: 100%;
                }
            }
        }
    }
}

.header-image-blocks {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    position: relative;
    margin-top: -19.2rem;
    top: 121px;
    @media (min-width: $breakpoint-medium) {
        margin-top: -14.8rem;
    }

    .header-block-item {
        min-width: 288px;
        min-height: 288px;

        img {
            width: 130px;
            margin-bottom: 4rem;
        }

        p {
            font-weight: $font-weight-bold;
        }

        @media (min-width: $breakpoint-large) {
            min-width: 228px;
            min-height: 228px;
            img {
                width: 100px;
            }
        }
        @media (min-width: $breakpoint-xlarge) {
            min-width: 339px;
            min-height: 339px;
            img {
                width: 152px;
            }
        }

        .block-item_wrapper {
            background: $kleur-white;
            background-repeat: no-repeat;
            border: 2px solid $kleur-blue;
            border-radius: 0.8rem;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            color: $kleur-almost-black;
            text-decoration: none;
            font-size: 2.2rem;
            font-weight: $font-weight-bold;

            &:hover {
                border: 2px solid transparent;
            }

            @media (min-width: $breakpoint-medium) and (max-width: 1023px) {
                height: 360px;
            }

            p {
                margin-bottom: 2rem;
            }
        }

        &:nth-child(1) {
            .block-item_wrapper {
                background: url("/theme/vrijopnaam/svg/doelgroep_icoon_grond.svg") no-repeat, white;
                background-position: center 69px;
                background-size: 129px 130px;

                &:hover {
                    background: url("/theme/vrijopnaam/svg/doelgroep_icoon_grond.svg") no-repeat, $kleur-orange;
                    background-position: center 69px;
                    background-size: 129px 130px;
                }

                @media (min-width: $breakpoint-medium) {
                    background-position: center 86px;
                    background-size: 161px 163px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_grond.svg") no-repeat, $kleur-orange;
                        background-position: center 86px;
                        background-size: 161px 163px;
                    }
                }
                @media (min-width: $breakpoint-large) {
                    background-position: center 56px;
                    background-size: 100px 100px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_grond.svg") no-repeat, $kleur-orange;
                        background-position: center 56px;
                        background-size: 100px 100px;
                    }
                }
                @media (min-width: $breakpoint-xlarge) {
                    background-position: center 81px;
                    background-size: 152px 153px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_grond.svg") no-repeat, $kleur-orange;
                        background-position: center 81px;
                        background-size: 152px 153px;
                    }
                }
            }
        }

        &:nth-child(2) {
            .block-item_wrapper {
                background: url("/theme/vrijopnaam/svg/doelgroep_icoon_gemeente.svg") no-repeat, white;
                background-position: center 70px;
                background-size: 126px 127px;

                &:hover {
                    background: url("/theme/vrijopnaam/svg/doelgroep_icoon_gemeente.svg") no-repeat, $kleur-orange;
                    background-position: center 70px;
                    background-size: 126px 127px;
                }

                @media (min-width: $breakpoint-medium) {
                    background-position: center 88px;
                    background-size: 157px 159px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_gemeente.svg") no-repeat, $kleur-orange;
                        background-position: center 88px;
                        background-size: 157px 159px;
                    }
                }
                @media (min-width: $breakpoint-large) {
                    background-position: center 56px;
                    background-size: 100px 100px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_gemeente.svg") no-repeat, $kleur-orange;
                        background-position: center 56px;
                        background-size: 100px 100px;
                    }
                }
                @media (min-width: $breakpoint-xlarge) {
                    background-position: center 83px;
                    background-size: 148px 150px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_gemeente.svg") no-repeat, $kleur-orange;
                        background-position: center 83px;
                        background-size: 148px 150px;
                    }
                }
            }
        }

        &:nth-child(3) {
            .block-item_wrapper {
                background: url("/theme/vrijopnaam/svg/doelgroep_icoon_omwonende.svg") no-repeat, white;
                background-position: center 70px;
                background-size: 127px 115px;

                &:hover {
                    background: url("/theme/vrijopnaam/svg/doelgroep_icoon_omwonende.svg") no-repeat, $kleur-orange;
                    background-position: center 70px;
                    background-size: 127px 115px;
                }

                @media (min-width: $breakpoint-medium) {
                    background-position: center 88px;
                    background-size: 158px 144px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_omwonende.svg") no-repeat, $kleur-orange;
                        background-position: center 88px;
                        background-size: 158px 144px;
                    }
                }
                @media (min-width: $breakpoint-large) {
                    background-position: center 56px;
                    background-size: 100px 91px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_omwonende.svg") no-repeat, $kleur-orange;
                        background-position: center 56px;
                        background-size: 100px 91px;
                    }
                }
                @media (min-width: $breakpoint-xlarge) {
                    background-position: center 83px;
                    background-size: 149px 136px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_omwonende.svg") no-repeat, $kleur-orange;
                        background-position: center 83px;
                        background-size: 149px 136px;
                    }
                }
            }
        }

        &:nth-child(4) {
            .block-item_wrapper {
                background: url("/theme/vrijopnaam/svg/doelgroep_icoon_energiecoop.svg") no-repeat, white;
                background-position: center 69px;
                background-size: 129px 129px;

                &:hover {
                    background: url("/theme/vrijopnaam/svg/doelgroep_icoon_energiecoop.svg") no-repeat, $kleur-orange;
                    background-position: center 69px;
                    background-size: 129px 129px;
                }

                @media (min-width: $breakpoint-medium) {
                    background-position: center 86px;
                    background-size: 161px 161px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_energiecoop.svg") no-repeat, $kleur-orange;
                        background-position: center 86px;
                        background-size: 161px 161px;
                    }
                }
                @media (min-width: $breakpoint-large) {
                    background-position: center 56px;
                    background-size: 100px 100px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_energiecoop.svg") no-repeat, $kleur-orange;
                        background-position: center 56px;
                        background-size: 100px 100px;
                    }
                }
                @media (min-width: $breakpoint-xlarge) {
                    background-position: center 81px;
                    background-size: 152px 152px;
                    &:hover {
                        background: url("/theme/vrijopnaam/svg/doelgroep_icoon_energiecoop.svg") no-repeat, $kleur-orange;
                        background-position: center 81px;
                        background-size: 152px 152px;
                    }
                }
            }
        }
    }
}

.zoom-in {
    cursor: zoom-in;
}
