.zonneparken {

  --kleur-blue-light: #EAF6FE;
  --kleur-blue-border: #c5e8ff;


  .table-wrapper {
    background-color: var(--kleur-blue-light);
  }

  table.voor-wie {
    margin-bottom: 0;
    
    thead {

    & tr {
      border-bottom: 1px solid var(--kleur-blue-border);
    }

    & th {
        background-color: var(--kleur-blue-light);
      }

    }

    tbody tr > td:first-of-type {
      background-color: var(--kleur-blue-light);
      width: min-content;
    }

    tbody tr  td:nth-child(2) {
      background-color: white;
    }

    tbody tr > td:nth-child(3) {
      background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(234,246,254,1) 75%);
    }

    tbody tr {
        border-bottom: 1px solid var(--kleur-blue-border);
    }

    th,
    td {
      padding: 12px 17px;
      vertical-align: top;
    }


    @media screen and (min-width: 1430px) {
      th:nth-child(1) {
        width: 300px;
      }

      th:nth-child(2) {
        width: 400px;
      }

    }
  }


  table.aanpak {
    background-color: var(--kleur-blue-light);

    img {
      max-width: 44px;
      border-radius: 0;
    }

    tbody tr > td:first-of-type {
      padding: 12px 0 0 17px;
      min-width: 64px;  
    }

    td em {
      color: #636363;
    }

    tbody tr > td:nth-child(2) {
      min-width: 200px;
    }

    tbody tr > td:nth-child(3) {
      min-width: 200px;
    }

    th,
    td {
      padding: 12px 17px;
      vertical-align: top;
    }

    thead tr {
      border-bottom: 1px solid var(--kleur-blue-border);
    }

  }

  table.mobiel {
    background-color: var(--kleur-blue-light);
  
    th {
      border-bottom: 1px solid var(--kleur-blue-border);
    }

    th,
    td {
      padding: 12px 17px;
      vertical-align: top;
    }

    .divider {
      background-color: white;
      padding: 0;
      line-height: 16px;
    }

  }


  table.over-ons {

    th,
    td {
      padding: 12px 0;
      vertical-align: top;
    }

    td.getal {
      font-size: 28px;
      font-weight: bold;
      padding-right: 17px;
    }

    td.icoon {
      width: 160px;
      text-align: center;
      padding-left: 17px;
      padding-right: 17px;
      img {
        width: 44px;
        height: 44px;
        border-radius: 0;
      }
      &.panels {
        img{
          width: 48px;
          height: 30px;
        }
      }
      &.lightning {
        img {
          width: 24px;
          height: 32px;
        }
      }
      &.euroenergie {
        img {
          width: 48px;
          height: 48px;
        }
      }

    }

    td.tekst {
      padding-left: 17px;
      padding-right: 17px;
    }

    @media screen and (max-width: 500px) {

      .res-row {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }

      td.icoon {
        width: auto;
      }

      td.tekst {
       width: 100%;
      }

    }


  }

}