html {
    font-size: $font-size-base;
}

body {
    font-size: $font-size-base;
    font-family: $base-font-family;
    font-weight: 500;
    color: $base-font-color;
    line-height: 1.5;

    //@include respond-to(xlarge-screens) {
    //  font-size: 1.125rem; //18px
    //}
}

h1, h2, h3, h4, h5 {
    margin-top: 0;
    font-family: $base-header-font-family;
    color: $base-header-font-color;
    margin-bottom: 0.5em;
    line-height: 1.25;
    word-wrap: break-word;
    text-transform: none;

    @media (max-width: $breakpoint-medium) {
        hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
    }
}

h1 {
    font-size: $h1-font-size-small;
    font-weight: $h1-font-weight;
    color: $kleur-almost-black;
    text-transform: uppercase;
    line-height: 3.3rem;

    @media (min-width: $breakpoint-large) {
        font-size: $h1-font-size-medium;
        line-height: 5.5rem;
    }

    @media (min-width: $breakpoint-xlarge) {
        font-size: $h1-font-size-large;
    }

    &.text-white {
        text-shadow: 0 0 24px rgba(0, 0, 0, 0.5);
    }

    &.text-black {
        text-shadow: 0 0 24px rgba(255, 255, 255, 0.5);
    }
}


h2, .h2, {
    font-size: $h2-font-size-medium;
    font-weight: $h2-font-weight;
    line-height: 2.8rem;

    text-transform: uppercase;
    color: #327cac;


    @media (min-width: $breakpoint-xlarge) {
        font-size: $h2-font-size-large;
        line-height: 4rem;
        margin-bottom: 3.2rem;
    }

    &.heading {
        text-transform: uppercase;
    }
}

b, strong {
    font-weight: bold;
}

.bg--blue h2,
.bg--oranje h2 {
    color: rgb(57, 60, 63);
    text-transform: uppercase;
}

h3, .h3 {
    font-family: $base-font-family;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: 3.2rem;
}

.h3 {
    text-transform: none;
    color: $base-header-font-color;
    margin-bottom: 0.5em;
}

h4, .h4 {
    font-family: $base-font-family;
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
}

.h4 {
    text-transform: none;
    color: $base-header-font-color;
    margin-bottom: 0.5em;
}

h5 {
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
}

p, ul, ol, dl, pre, blockquote, table {
    margin: 0 0 1.6rem 0;
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    line-height: 2.2rem;
}

span, div {
    font-size: 1.8rem;
    font-weight: $font-weight-light;
    line-height: 2.2rem;
}

.date {
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
}

a {
    color: $kleur-blue-wcag;
}

button {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 22px;

}

.footer {
    p {
        font-size: 1.4rem;
        font-weight: $font-weight-light;
        line-height: 2rem;
    }
}

.usp-footer {
    font-size: 1.4rem;
    font-weight: $font-weight-light;

    strong {
        font-family: $base-header-font-family;
        font-size: 2.2rem;
        font-weight: $font-weight-bold;
    }
}

blockquote {
    background-color: #F5F5F5;
    border-left: 5px solid $kleur-blue-light-2;
    padding: 1.25em 1.5em 0.1em;
    font-style: italic;
    color: #31363A;
}

blockquote p {
    margin: 0;
    margin-bottom: 2em !important;
}

.popover-body,
.popover-title {
    font-size: 1.8rem;
    font-family: $base-font-family;
}

.c-registration-form__footer--right {
    .text-placeholder {
        font-size: 1.4rem;
    }
}

/* Styling of hexagon on partner page */

@media (min-width: 320px) {
    .d-sm-flex.justify-content-sm-center.d-md-none.c-header-image--overlaid-mobile {
        display: table !important;
    }
}

@media (min-width: 768px) {
    .d-sm-flex.justify-content-sm-center.d-md-none.c-header-image--overlaid-mobile {
        display: none !important;
    }
}
