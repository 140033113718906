//.mapboxgl-marker {
//  svg {
//    circle {
//      fill: black;
//      opacity: 0.5;
//    }
//  }
//}

.marker {
  background-size: cover;
  width: 27px;
  height: 41px;
  cursor: pointer;
}

.marker--green {
  background-image: url(/theme/vrijopnaam/svg/locatie-groen.svg);
}

.marker--white {
  background-image: url(/theme/vrijopnaam/svg/locatie-wit.svg);
}

.marker--orange {
  background-image: url(/theme/vrijopnaam/svg/locatie-oranje.svg);
}

.marker--blue {
  background-image: url(/theme/vrijopnaam/svg/locatie-blauw.svg);
}

.marker--yellow {
  background-image: url(/theme/vrijopnaam/svg/locatie-geel.svg);
}

.mapboxgl-popup-content {

  padding: 0 !important;
  border: 3px solid #979797;
  border-radius: 14px !important;

  p {
    font-family: $base-font-family;
    font-size: 1.8rem;
    line-height: 1.7rem;
    strong {
      margin-bottom: .8rem;
    }
  }

  img {
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.mapboxgl-popup-anchor-bottom {
  max-width: 288px !important;

  .mapboxgl-popup-tip {
    border: 14px solid transparent;
    position: relative;
    top: -4px;
  }
}

.mapboxgl-popup-content {
  width: 288px !important;
  button {
    font-family: $base-font-family;
  }
  .mapboxgl-popup-close-button {
    background-color: white;
    border-radius: 8px;
    top: 8px;
    right: 8px;
    font-size: 2.61rem;
    font-weight: bold;
    padding: 12px;
    line-height: 16px;
    &:hover {
      background-color: $kleur-white;
      color: rgba(57, 60, 63, .75);
    }
  }
}

.mapboxgl-popup-tip {
  border-bottom-color: #979797 !important;
}

#map {
  margin-bottom: 0!important;
}

.map-legenda {
  margin-bottom: 88px;
  position: relative;
  top: -1px;
  .legenda {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: white;
    flex-wrap: wrap;
    .legenda-item {
      display: flex;
      @media (max-width: $breakpoint-medium) {
        justify-content: center;
        width: 100%;
      }
    }
    .oval {
      width: 24px;
      height: 24px;
      border-radius: 50px;
      margin-right: 0.5rem;

      &.geel {
        background-color: #fbdc2d;
      }

      &.groen {
        background-color: #6dd155;
      }

      &.blauw {
        background-color: #6dcef5;
      }
    }

    span {
      margin-right: 2rem;
    }
  }
}

div.mapboxgl-ctrl-bottom-left {
  display: none;
}

