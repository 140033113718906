.row img  { width: 100%;}

.article-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  div + div {
    padding-left: 0;
  }
}

.background-overlay {
  background-color: rgba(0,0,0,0.5);
  height: 100vh;
  width: 100vw;
  display: block;
  z-index: 1025;
  position: fixed;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

.col-lg-6 {
  max-width: 702px !important;
}

/*

.c-registration-main {
  overflow-x: hidden;
  overflow-y: hidden;
} 

*/
