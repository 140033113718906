$border-width: 0;
$border-radius: 8px;

$list-inline-padding: .5rem;

// Disable RFS by setting $enable-responsive-font-sizes to false
$enable-responsive-font-sizes: false;

// Color system

$white: $kleur-white;
$black: $kleur-almost-black;

$colors: ();
$colors: (
    "white": $white,
    "almost-black": $black,
    "grey": $kleur-grey,
    "grey-ligth": $kleur-grey-light,
    "light-grey": $kleur-light-grey,
    "light-grey-v6": $kleur-light-grey-v6,
    "grey-ligth-2": $kleur-grey-light-2,
    "grey-ligth-3": $kleur-grey-light-3,
    "grey-ligth-4": $kleur-grey-light-4,
    "grey-ligth-5": $kleur-grey-light-5,
    "grey-ligth-6": $kleur-grey-light-6,
    "grey-dark": $kleur-grey-dark,
    "orange": $kleur-orange,
    "orange-dark": $kleur-orange-dark,
    "orange-wcag": $kleur-orange-wcag,
    "orange-light": $kleur-orange-light,
    "blue": $kleur-blue,
    "blue-wcag": $kleur-blue-wcag,
    "blue-dark": $kleur-blue-dark,
    "blue-light": $kleur-blue-light,
    "blue-light-2": $kleur-blue-light-2,
    "green": $kleur-green,
    'green-wcag': $kleur-green-wcag
);

$primary:       $kleur-blue;
$secondary:     $kleur-orange;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;

//$theme-colors: ();
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "white": $white,
  "black": $black,
  "almost-black": $kleur-almost-black,
  "grey": $kleur-grey,
  "grey-ligth": $kleur-grey-light,
  "light-grey": $kleur-light-grey,
  "light-grey-v6": $kleur-light-grey-v6,
  "grey-ligth-2": $kleur-grey-light-2,
  "grey-ligth-3": $kleur-grey-light-3,
  "grey-ligth-4": $kleur-grey-light-4,
  "grey-ligth-5": $kleur-grey-light-5,
  "grey-ligth-6": $kleur-grey-light-6,
  "grey-dark": $kleur-grey-dark,
  "orange": $kleur-orange,
  "orange-dark": $kleur-orange-dark,
  "orange-wcag": $kleur-orange-wcag,
  "orange-light": $kleur-orange-light,
  "blue": $kleur-blue,
  "blue-wcag": $kleur-blue-wcag,
  "blue-dark": $kleur-blue-dark,
  "blue-light": $kleur-blue-light,
  "blue-light-2": $kleur-blue-light-2,
  "green": $kleur-green,
  'green-wcag': $kleur-green-wcag
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
//$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:            $base-font-family;
// stylelint-enable value-keyword-case

$font-size-base: 0.625rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base;
$font-size-sm: $font-size-base;

$line-height-base:  1.5;

$headings-font-family: $base-header-font-family;
$headings-line-height: 1;
$headings-color: $kleur-almost-black;

// Color system

$white: $kleur-white;
$black: $kleur-almost-black;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 16px;
$spacers: (
        0: 0,
        xxs: ($spacer * .25), // 4px
        xs: ($spacer * .5), // 8px
        s: ($spacer * .75), // 12px
        m: $spacer, // 16px
        l: ($spacer * 1.5), // 24px
        xl: ($spacer * 2), // 32px
        xxl: ($spacer * 2.5), // 40px
        xxxl: ($spacer * 5.5), // 88px
        1: ($spacer * .25),
        2: ($spacer * .5),
        3: $spacer,
        4: ($spacer * 1.5),
        5: ($spacer * 3)
);

// Links
//
// Style anchor elements.

$link-color: $kleur-blue-wcag;
$link-decoration: underline;
$link-hover-color: $kleur-almost-black;
$link-hover-decoration: underline;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
//$emphasized-link-hover-darken-percentage: 15% !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 320px,
  md: 768px,
  lg: 1024px,
  xl: 1430px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm: 768px,
    md: 1427.8px,
    lg: 1427.9px,
    xl: 1428px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

//$grid-columns:                12 !default;
$grid-gutter-width: 3.2rem;
//$grid-row-columns:            6 !default;

// Navs

$nav-link-padding-y: 0;
$nav-link-padding-x: 0;
//$nav-link-disabled-color:           $gray-600 !default;
//
//$nav-tabs-border-color:             $gray-300 !default;
//$nav-tabs-border-width:             $border-width !default;
//$nav-tabs-border-radius:            $border-radius !default;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
//$nav-tabs-link-active-color:        $gray-700 !default;
//$nav-tabs-link-active-bg:           $body-bg !default;
//$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
//
//$nav-pills-border-radius:           $border-radius !default;
//$nav-pills-link-active-color:       $component-active-color !default;
//$nav-pills-link-active-bg:          $component-active-bg !default;
//
//$nav-divider-color:                 $gray-200 !default;
//$nav-divider-margin-y:              $spacer / 2 !default;

// Navbar

$navbar-padding-x: 0;
$navbar-padding-y: 0;

//$navbar-nav-link-padding-x: 1.6rem;

//$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

//$navbar-toggler-padding-y:          .25rem !default;
//$navbar-toggler-padding-x:          .75rem !default;
//$navbar-toggler-font-size:          $font-size-lg !default;
//$navbar-toggler-border-radius:      $btn-border-radius !default;

//$navbar-dark-color:                 rgba($white, .5) !default;
//$navbar-dark-hover-color:           rgba($white, .75) !default;
//$navbar-dark-active-color:          $white !default;
//$navbar-dark-disabled-color:        rgba($white, .25) !default;
//$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color: $kleur-almost-black;
$navbar-light-hover-color: $kleur-blue-wcag;
$navbar-light-active-color: $kleur-blue-wcag;
//$navbar-light-disabled-color:       rgba($black, .3) !default;
//$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
//$navbar-light-toggler-border-color: rgba($black, .1) !default;

//$navbar-light-brand-color:                $navbar-light-active-color !default;
//$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
//$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
//$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;

// Pagination

$pagination-padding-y: .9rem;
$pagination-padding-x: 1.6rem;
$pagination-padding-y-sm: .9rem;
$pagination-padding-x-sm: 1.6rem;
$pagination-padding-y-lg: .9rem;
$pagination-padding-x-lg: 1.6rem;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $kleur-almost-black;
$pagination-bg:                     $kleur-white;
$pagination-border-width:           .1rem;
$pagination-border-color:           $kleur-almost-black;

//$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
//$pagination-focus-outline:          0 !default;

$pagination-hover-color: $kleur-almost-black;
$pagination-hover-bg: $kleur-blue-light;
$pagination-hover-border-color: $kleur-almost-black;

$pagination-active-color: $kleur-almost-black;
$pagination-active-bg: $kleur-blue;
$pagination-active-border-color: $kleur-almost-black;

$pagination-disabled-color: #6e787d;
$pagination-disabled-bg: $kleur-white;
$pagination-disabled-border-color: $kleur-grey-light;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.9rem;
$input-btn-padding-x: 1.6rem;
$input-btn-font-family: $base-font-family;
$input-btn-font-size: 1.8rem;
//$input-btn-line-height:       $line-height-base !default;

//$input-btn-focus-width:       .2rem !default;
//$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
//$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-padding-x-sm: $input-btn-padding-x;
$input-btn-font-size-sm: $input-btn-font-size;
//$input-btn-line-height-sm:    $line-height-sm !default;

$input-btn-padding-y-lg: 1.8rem;
$input-btn-padding-x-lg: 1.6rem;
$input-btn-font-size-lg: $input-btn-font-size;
//$input-btn-line-height-lg:    $line-height-lg !default;
//
//$input-btn-border-width:      $border-width !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
//$btn-font-family:             $input-btn-font-family !default;
$btn-font-size: 1.8rem;
$btn-line-height: 2.2rem;
//$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping
//
//$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
//$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
//$btn-font-size-sm:            $input-btn-font-size-sm !default;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;
//
//$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
//$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
//$btn-font-size-lg:            $input-btn-font-size-lg !default;
//$btn-line-height-lg:          $input-btn-line-height-lg !default;
//
//$btn-border-width:            $input-btn-border-width !default;
//
$btn-font-weight: $font-weight-bold;
//$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
//$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
//$btn-disabled-opacity:        .65 !default;
//$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
//
//$btn-link-disabled-color:     $gray-600 !default;
//
//$btn-block-spacing-y:         .5rem !default;

// Forms

$label-margin-bottom:                   .5rem !default;

$input-padding-y: 0.9rem;
$input-padding-x: 1.6rem;
$input-font-family: $base-font-family;
$input-font-size: 1.8rem;
$input-font-weight: $font-weight-light;
//$input-line-height: 1.2;

//$input-padding-y-sm:                    $input-btn-padding-y-sm !default;
//$input-padding-x-sm:                    $input-btn-padding-x-sm !default;
//$input-font-size-sm:                    $input-btn-font-size-sm !default;
//$input-line-height-sm:                  $input-btn-line-height-sm !default;
//
//$input-padding-y-lg:                    $input-btn-padding-y-lg !default;
//$input-padding-x-lg:                    $input-btn-padding-x-lg !default;
//$input-font-size-lg:                    $input-btn-font-size-lg !default;
//$input-line-height-lg:                  $input-btn-line-height-lg !default;

$input-bg: $white;
$input-disabled-bg: $white;

$input-color: $kleur-almost-black;
$input-border-color: $kleur-grey;
$input-border-width: 1px;
//$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius: 0.8rem;
//$input-border-radius-lg:                $border-radius-lg !default;
//$input-border-radius-sm:                $border-radius-sm !default;

//$input-focus-bg:                        $input-bg !default;
$input-focus-border-color: $kleur-almost-black;
$input-focus-color: $kleur-grey-light;
$input-focus-width: 0.2rem;
//$input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

$input-placeholder-color: $kleur-grey-light;
//$input-plaintext-color:                 $body-color !default;

//$input-height-border:                   $input-border-width * 2 !default;

//$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
//$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
//$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) !default;

//$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
//$input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
//$input-height-lg:                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

//$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Form validation

//$form-feedback-margin-top:          $form-text-margin-top !default;
//$form-feedback-font-size:           $small-font-size !default;
$form-feedback-valid-color: $kleur-grey;
$form-feedback-invalid-color: $kleur-error;

//$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
//$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
//$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
//$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

//$form-validation-states: () !default;
//$form-validation-states: map-merge(
//                (
//                        "valid": (
//                                "color": $form-feedback-valid-color,
//                                "icon": $form-feedback-icon-valid
//                        ),
//                        "invalid": (
//                                "color": $form-feedback-invalid-color,
//                                "icon": $form-feedback-icon-invalid
//                        ),
//                ),
//                $form-validation-states
//);

// Popovers

$popover-font-size: 1.8rem;
$popover-bg: $kleur-white;
$popover-max-width: 288px;
$popover-border-width: 4px;
$popover-border-color: rgba(0, 0, 0, 0.15);
$popover-border-radius: 1.6rem;
//$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.15);

$popover-header-bg: $kleur-white;
$popover-header-color: $kleur-almost-black;
$popover-header-padding-y: 16px;
$popover-header-padding-x: 16px;

$popover-body-color: $kleur-almost-black;
$popover-body-padding-y: 1.6rem;
$popover-body-padding-x: 1.6rem;

$popover-arrow-width: 2rem;
$popover-arrow-height: 3rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.6rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 1.6rem;

//$modal-dialog-margin:               .5rem !default;
//$modal-dialog-margin-y-sm-up:       1.75rem !default;
//
//$modal-title-line-height:           $line-height-base !default;
//
//$modal-content-color:               null !default;
//$modal-content-bg:                  $white !default;
//$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width: 4px;
$modal-content-border-radius: 8px;
//$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
//$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs: 0 0 0 4px rgba(0, 0, 0, 0.15);
$modal-content-box-shadow-sm-up: 0 0 0 4px rgba(0, 0, 0, 0.15);
//
//$modal-backdrop-bg:                 $black !default;
//$modal-backdrop-opacity:            .5 !default;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
//$modal-header-padding-y:            1rem !default;
//$modal-header-padding-x:            1rem !default;
//$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility
//
//$modal-xl:                          1140px !default;
//$modal-lg:                          800px !default;
//$modal-md:                          500px !default;
//$modal-sm:                          300px !default;
//
//$modal-fade-transform:              translate(0, -50px) !default;
//$modal-show-transform:              none !default;
//$modal-transition:                  transform .3s ease-out !default;
//$modal-scale-transform:             scale(1.02) !default;

//
// Lists
//

.list-unstyled {
  @include list-unstyled();
}

// Inline turns list items into inline-block
.list-inline {
  @include list-unstyled();
}
.list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}
