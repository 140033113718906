.c-news {
  article {
    a {
      color: $kleur-almost-black;
      text-decoration: none;
    }
  }
}

.c-news-detail {
  h2 {
    font-family: $base-font-family;
    font-size: 2.8rem;
    color: $kleur-almost-black;
    font-weight: bold;
    text-transform: initial;
    margin-bottom: 1.6rem;
  }
  h2.subtitle {
    font-size: 2.8rem;
    color: $kleur-almost-black;
    text-transform: none;
    margin-bottom: 16px;
  }
  h3 {
    font-size: 2.5rem;
  }
  h5, h6 {
    font-family: $base-font-family;
    font-size: 2.2rem;
  }
  p:not(.date) {
    margin-bottom: 3.2rem;
  }
}

.c-news-item-container--two-column {
  div {
    @media (min-width: $breakpoint-medium) {
      column-count: 2;
    }
  }
}

.c-news-item {
  border: solid 1px $kleur-blue-light-2;
  border-radius: 8px;
  h2 {
    text-transform: none;
  }
  &:hover {
    background-color: $kleur-blue-light;
    border-color: $kleur-blue-light;
    cursor: pointer;
    text-decoration: none;
  }
}