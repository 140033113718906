// Styles for contact blocks in Questions page

.c-contact-blocks-container {
    p {
        text-align: center;
    }

    a {
        text-decoration: underline;
    }

    .textual {
        max-width: 228px;
    }
}

.c-contact-questions {
    h2 {
        margin-bottom: 106px;
        text-transform: uppercase;
        color: $kleur-almost-black;
    }

    p {
        font-size: 1.4rem;
        @media (min-width: $breakpoint-xlarge) {
            font-size: 1.8rem;
        }
    }

    .container {
        position: relative;
        top: -83px;
    }
}

.c-product-cards-container {
    z-index: 1;
    position: relative;

    img {
        border-bottom-right-radius: 1.6rem;
        border-top-right-radius: 1.6rem;
        border-top-left-radius: 1.6rem;
    }

    article {
        border-radius: 1.6rem;
        //display: flex;
        //flex-direction: column;
    }

    h2 {
        text-transform: uppercase;
        color: $kleur-almost-black;
        //flex: 1 1 0%;
    }

    .bg-black {
        .textual,
        h3 {
            color: $kleur-white;
        }
    }
}

.c-news-cards-container {
    article {
        border-radius: 1.6rem;
        background-color: $kleur-blue-light-2;
    }

    h2 {
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
    }

    .textual {
        height: 190px;
        z-index: 1;
        position: relative;
        border-bottom-right-radius: 1.6rem;
        border-top-right-radius: 1.6rem;
        border-top-left-radius: 1.6rem;
    }

    img {
        border-bottom-right-radius: 1.6rem;
        border-bottom-left-radius: 1.6rem;
    }

    .visual {
        margin-top: -1.6rem;
    }

    .bg-black {
        .textual,
        h3 {
            color: $kleur-white;
        }
    }
}

.c-registration-block-wrapper {
    h1 {
        @media (min-width: $breakpoint-medium) {
            margin-bottom: 71px;
            max-width: 750px;
            word-break: break-word;
        }
    }

    & .c-header-image--overlaid {
        position: absolute;
        bottom: 0;
        margin-bottom: -53px;
        display: flex;
        gap: 2.4rem;
        align-items: flex-end;

        img {
            height: 145px; /* Forceer een vaste hoogte */
            width: auto; /* Breedte schaalt proportioneel */
            display: block; /* Voorkom inline-ruimte */
        }

        img[src$=".svg"] {
            width: auto; /* Zorg dat SVG proportioneel blijft */
            height: 145px; /* Forceer dezelfde hoogte */
            object-fit: contain; /* Behoud de inhoudsverhouding */
        }

        @media (max-width: $breakpoint-medium) {
            display: none;
        }
    }

    @media (min-width: $breakpoint-medium) {
        position: relative;
    }

    .registration-continue-wrapper {
        span {
            font-weight: 700;
            font-size: 1.4rem;
        }

        .header {
            font-weight: 300;
            font-size: 1.4rem;
        }

        .contract {
            font-weight: 700;
            font-size: 1.4rem;
        }

        .total {
            display: flex;
            justify-content: center;
        }

        .total-textual {
            align-self: center;
        }

        .geschatteKostenMaand {
            font-weight: 700;
            font-size: 2.8rem;
        }

        .btn {
            display: flex;
            align-items: center;
            width: max-content;
            margin: 0 auto;
        }
    }
}


.c-header-image--overlaid-mobile {
    margin: 8rem auto -2rem  auto;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    gap: 2rem;

    & .consumenten-bond {
        height: 127px;
        width: 145px;
    }

    & .overstappen {
        height: 127px;
        width: 117px;
    }

    @media (min-width: $breakpoint-medium) {
        display: none;
    }
}

.c-registration-block {
    background-color: $kleur-white;
    background-image: url(/theme/vrijopnaam/svg/zon-blue.svg);
    background-repeat: no-repeat;
    background-position: calc(100% + 71px) 0;
    padding: 0;
    border-radius: 1.6rem;
    position: absolute;
    bottom: -34px;

    @media (min-width: $breakpoint-medium) {
        position: absolute;
        right: 0;
    }

    @media (min-width: $breakpoint-large) {
        bottom: -40px;
    }

    ul {
        padding-left: 0;
        padding-right: 2rem;
        @media (min-width: $breakpoint-medium) {
            padding-right: 2.6rem;
        }
        @media (min-width: $breakpoint-large) {
            padding-right: 2.2rem;
        }
        @media (min-width: $breakpoint-xlarge) {
            padding-right: 3.4rem;
        }
        list-style: none;
    }

    li {
        background-image: url(/theme/vrijopnaam/svg/vinkje-blue.svg);
        background-repeat: no-repeat;
        //margin-bottom: 32px;
        padding-left: 2.6rem;
        background-position: left 6px;
        margin-bottom: .8rem;

        &:last-child {
            margin-bottom: 2.4rem;
        }
    }
}

.c-registration-block--double {
    bottom: -76px;

    .c-registration-block__discount {
        min-height: 76px;
        display: flex;
        justify-content: center;

        p {
            align-self: center;
        }
    }
}

.c-registration-block__body {
    border-top: 1px solid $kleur-blue-light-2;
    border-right: 1px solid $kleur-blue-light-2;
    border-left: 1px solid $kleur-blue-light-2;
    border-radius: 1.6rem 1.6rem 0 0;
    padding: 0 1.6rem;

    @media (min-width: $breakpoint-large) {
        padding-top: 84px;
    }
}

.c-registration-block__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    a:not(.btn) {
        font-size: 1.4rem;
    }

    .btn {
        @media (max-width: $breakpoint-medium) {
            margin-left: -7px;
            margin-right: -7px;
        }
    }
}

.c-registration-block__reviews {
    p {
        font-size: 1.4rem;
    }

    strong {
        font-size: 2.2rem;
        font-family: $base-header-font-family;
        font-weight: $font-weight-bold;
    }
}

.c-registration-block__discount {
    border-radius: 0 0 1.6rem 1.6rem;
    background-color: $kleur-blue;
    text-align: center;
    padding: 6px 0;
    justify-content: center;
    display: flex;

    @media (min-width: $breakpoint-large) {
        padding: 9px 0;
    }

    & img {
        margin-right: 0.6rem;
    }

    p {
        margin-bottom: 0;
    }
}

.c-registration-block__discount-yellow {
    position: relative;
    border-radius: 16px 16px 0 0;
    padding: 6px 0;
    text-align: center;
    margin: -1px -17px 16px;
    background-color: #fbdc2d;

    @media (min-width: $breakpoint-large) {
        position: absolute;
        padding: 24px 32px;
        border-radius: 8px;
        left: 0;
        top: -16px;
    }

    & p {
        margin: 0;
        font-weight: bold;
    }
}

.c-review-cards-container {
    h2 {
        text-transform: uppercase;
    }

    &--listing {
        column-count: 1;
        @media (min-width: $breakpoint-medium) {
            column-count: 2;
        }
        @media (min-width: $breakpoint-large) {
            column-count: 3;
        }
    }
}

.c-review-cards-container__bottom {
    p {
        font-size: 2.2rem;
        font-weight: $font-weight-bold;
    }

    span {
        font-size: 2.8rem;
        font-weight: $font-weight-bold;
    }
}

.c-review-cards-columns {
    page-break-inside: avoid;
}

.c-review-card {
    height: 100%;
    border: 1px solid $kleur-blue-light-2;
    border-radius: 16px;

    .textual {
        flex-wrap: nowrap;
    }
}

.c-review-card__footer {
    p {
        margin-bottom: 0;
        text-align: right;

        strong {
            font-weight: $font-weight-bold;
        }
    }
}

.c-review-card__head {
    flex-wrap: nowrap;
    display: flex;
    justify-content: space-between;

    .stars {
        font-size: .2rem;

        svg {
            fill: $kleur-orange;
        }
    }
}

.c-review-card__score {
    span {
        border-radius: 50px;
        background-color: $kleur-orange;
        font-size: 2.8rem;
        font-weight: $font-weight-bold;
        width: 56px;
        height: 56px;
        display: inline-block;
        text-align: center;
        padding: 16px 0;
    }
}

.c-review-card__recommend-text {
    p {
        font-weight: $font-weight-bold;
    }

    svg {
        vertical-align: baseline;
    }
}

.c-zelf-opwekken {
    h2 {
        margin-bottom: 2.4rem;
    }

    ul {
        list-style: none;
        padding-left: 0;
        @media (min-width: $breakpoint-medium) {
            column-count: 2;
        }
        margin-bottom: 4rem;
        line-height: 0;
    }

    li {
        margin-bottom: 32px;
        padding-left: 5.6rem;
        padding-right: 4rem;
        background-repeat: no-repeat;
        background-position: left center;
        min-height: 4rem;
        display: flex;
        align-items: center;
        line-height: 2.4rem;
    }

    li:nth-child(1) {
        background-image: url(/theme/vrijopnaam/svg/euro-white.svg);
    }

    li:nth-child(2) {
        background-image: url(/theme/vrijopnaam/svg/icon-dial.svg);
    }

    li:nth-child(3) {
        background-image: url(/theme/vrijopnaam/svg/icon-agenda.svg);
    }

    li:nth-child(4) {
        background-image: url(/theme/vrijopnaam/svg/icon-cogs.svg);
    }

    li:nth-child(5) {
        background-image: url(/theme/vrijopnaam/svg/icon-recycle.svg);
    }

    li:nth-child(6) {
        background-image: url(/theme/vrijopnaam/svg/icon-smile.svg);
    }

    .c-zelf-opwekken__image {
        img {
            width: auto;
            position: absolute;
            width: auto;
            top: -51px;
            max-width: 220px;
        }
    }
}

.c-zelf-opwekken__image {
    justify-content: center;

    a {
        display: contents;
    }
}

.c-video-2col_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 16px;
    grid-template-rows: 150px 400px 1fr 16px;

    .bg--orange {
        background-color: $kleur-orange;
        border-radius: 8px;
        grid-column: 2 / 6;
        grid-row: 1 / span 4;
    }

    h3 {
        padding-left: 2.4rem;
        grid-row: 1 / span 1;
        grid-column: 2 / 5;
        align-self: end;
    }

    .video-placeholder {
        grid-column: 1 / 5;
        grid-row: 2 / span 1;

        figure {
            height: 100%;

            img {
                max-height: 400px;
                object-fit: cover;
                width: auto;
            }

            iframe {
                width: 100%;
                height: 100%;
            }

            .overlay-content {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                color: #fff; /* Pas aan naar wens */
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 8px;
            }
        }
    }

    .embed-responsive {
        border-radius: 8px;
    }
}

.zonneparken .c-video-2col_container {
    .video-placeholder {
        figure {
            img {
                max-height: unset;
            }
        }
    }
}

section:not(.bg--blue) .row > .align-self-center > h1,
section:not(.bg--blue) .row > .align-self-center > h2,
section .row > .offset-md-1 > h1,
section .row > .offset-md-1 > h2 {
    font-size: $h2-font-size-medium;
    font-weight: $h2-font-weight;
    line-height: 2.8rem;
    text-transform: uppercase;
    color: $kleur-blue-wcag;

    @media (min-width: $breakpoint-large) {
        font-size: $h2-font-size-large;
        line-height: 4rem;
        margin-bottom: 3.2rem;
    }
}

section img {
    border-radius: 8px;
}


.mapboxgl-ctrl-attrib-inner {
    font-size: 10px;
}

.c-welcome {
    .icon-facebook {
        width: 24px;
        height: 24px;
        background-image: url(/theme/vrijopnaam/svg/fb-black.svg);
    }

    .icon-instagram {
        width: 24px;
        height: 24px;
        background-image: url(/theme/vrijopnaam/svg/instagram-icon-black.svg);
    }

    .icon-twitter {
        width: 24px;
        height: 24px;
        background-image: url(/theme/vrijopnaam/svg/twitter.svg);
    }
    .icon {
        border-radius: 5px;
    }
}

.c-full-bleed-container.zekerheden {
    height: 1321px;
    @media (min-width: $breakpoint-medium) {
        height: 579px;
    }
    @media (min-width: $breakpoint-large) {
        height: 237px;
    }
    @media (min-width: $breakpoint-xlarge) {
        height: 319px;
    }

    .container {
        top: -83px;
        @media (min-width: $breakpoint-medium) {
            top: -77px;
        }
        @media (min-width: $breakpoint-large) {
            top: -64px;
        }
        @media (min-width: 1055px) {
            top: -66px;
        }
        @media (min-width: 1075px) {
            top: -66px;
        }
        @media (min-width: 1100px) {
            top: -69px;
        }
        @media (min-width: 1113px) {
            top: -70px;
        }
        @media (min-width: $breakpoint-xlarge) {
            top: -93px;
        }
    }
}