// Global buttons

.btn-max-content {
    width: max-content;
}

.c-button {
    border-radius: 8px;
    border: none;
    padding: 9px 16px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;

    &:hover {
        cursor: pointer;
    }
}

.c-button--primary {
    background-color: $btn-primary-color;
    color: $btn-primary-text-color;
}


// Sizes and text alignment

.c-button--double {
    padding: 0 16px;
    margin-bottom: 16px;
}

.c-button--full-width {
    width: 100%;
}

.c-button--text-left {
    text-align: left;
}

// Button colors

.c-button---blue {
    background-color: $kleur-blue;
}

.c-button--light-blue {
    background-color: $kleur-blue-light;
}

// Text colors

.c-button--orange-text {
    color: $kleur-orange;
}

.c-button--white-text {
    color: $kleur-white;
}

.c-button--blue-text {
    color: $kleur-blue;
}

.c-button--black-text {
    color: $kleur-almost-black;
}

// Next and previous buttons

.c-button-previous {
    border: none;
    background: inherit;
    padding: 0;
    text-align: left;

    &:hover {
        text-decoration: underline;
    }
}

.c-button-previous-news {
    width: 40px;
    height: 40px;
    display: flex;
    background: $kleur-grey-light-4;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

    &:hover {
        text-decoration: none;
    }

    i {
        color: $kleur-blue-wcag;
    }
}

.c-button--secondary {
    //border: 4px solid transparent;
    transition: all .2s ease-in-out;
    background-color: $btn-secondary-color;

    &:hover,
    &:focus {
        outline: 4px solid rgb(251, 220, 45);
        cursor: pointer;
        background-color: #212324;
    }

    &:focus:not(:focus-visible) {
        outline: 4px solid rgb(251, 220, 45);
    }
}

.c-button--enabled {
    background-color: #212324;
    outline: 4px solid rgb(251, 220, 45);

    &:hover,
    &:focus {
        background-color: #212324;
        color: $kleur-orange;
    }
}

//.c-button--secondary {
//    background-color: $btn-secondary-color;
//
//    &:hover,
//    &:focus {
//        cursor: pointer;
//        background-color: #212324;
//    }
//}

.c-button-previous {
    border: none;

    &:hover,
    &:focus {
        border: none;
        outline: none;
    }


}

.editbutton {
    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    float: left;
    background: rgba(128, 128, 128, 0.2);
    padding: 0.3rem 1rem;
}

.btn-green:hover, .btn-green:focus, .btn-green:active, .btn-green.active, .open > .dropdown-toggle.btn-green {
    color: $kleur-almost-black !important;
    background-color: $kleur-green-hover;
    border-color: $kleur-green-hover;
}

button#nextButton.c-button.btn-green.text-black.mb-xs {
    margin-top: 78px;
}

// Registration form buttons overrides
.c-registration-form__fields-container {
    .c-button-container.step-2 {
        display: block;

        .form-group.row {
            display: inline-block;
            min-width: 266px;
            margin-left: 0;
            margin-right: 3.2rem;
            margin-bottom: 1.6rem;

            @media (min-width: $breakpoint-medium) {
                min-width: 266px;
                &:nth-child(1n+2) {
                    margin-left: -4px;
                }
            }

            .col-sm-10 {
                min-width: 266px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 266px;
                }

                button {
                    width: 100%;
                    height: 80px;
                }
            }
        }
    }

    .step-3c {
        .form-group.row {
            display: inline-block;
            min-width: 266px;
            margin-left: 0;
            margin-right: 1.6rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 143px;
                width: 143px;
            }

            &:last-child {
                margin-right: 0;
            }

            .col-sm-10 {
                min-width: 266px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 143px;
                    width: 143px;
                }

                button {
                    width: 100%;
                    padding: 49px 14px 6px;
                    @media (min-width: $breakpoint-medium) {
                        width: 143px;
                        min-width: 13ch;
                    }
                }
            }
        }
    }

    .step-3d {
        .form-group.row {
            display: inline-block;
            min-width: 266px;
            margin-left: 0;
            margin-right: 1.6rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 167px;
                width: 167px;
            }

            &:last-child {
                margin-right: 0;
            }

            .col-sm-10 {
                min-width: 266px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 129px;
                    width: 159px;
                }

                button {
                    width: 100%;
                    padding: 49px 14px 6px;
                    @media (min-width: $breakpoint-medium) {
                        width: 167px;
                        min-width: 15ch;
                    }
                }
            }
        }
    }

    .step-4 {
        .form-group.row {
            display: inline-block;
            width: 266px;
            margin-left: 0;
            margin-right: 3.2rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 176px;
                width: 176px;
            }

            .col-sm-10 {
                min-width: 266px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 180px;
                    width: 180px;
                }

                button {
                    width: 100%;
                    height: 80px;
                    padding: 0 14px;
                }
            }
        }
    }

    .step-5 {
        .form-group.row {
            display: inline-block;
            min-width: 266px;
            margin-left: 0;
            margin-right: 3.2rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 236px;
                width: 236px;
            }

            .col-sm-10 {
                min-width: 266px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 236px;
                    width: 236px;
                }

                button {
                    width: 100%;
                    height: 80px;
                    padding: 0 14px;
                }
            }
        }
    }

    .step-5b {
        .form-group.row {
            display: inline-block;
            min-width: 288px;
            max-width: 288px;
            margin-left: 0;
            margin-right: 3.2rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 176px;
                width: 176px;
            }

            .col-sm-10 {
                min-width: 288px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 176px;
                    width: 176px;
                }

                button {
                    width: 100%;
                    height: 80px;
                    padding: 0 14px;
                }
            }
        }
    }

    .step-10 {
        .form-group.row {
            display: inline-block;
            min-width: 288px;
            max-width: 288px;
            margin-left: 0;
            margin-right: 3.2rem;
            margin-bottom: 1.6rem;
            @media (min-width: $breakpoint-medium) {
                min-width: 254px;
                width: 254px;
            }

            .col-sm-10 {
                min-width: 288px;
                max-width: 100%;
                padding: 0;
                height: 80px;
                @media (min-width: $breakpoint-medium) {
                    min-width: 254px;
                    width: 254px;
                }

                button {
                    width: 100%;
                    height: 80px;
                    padding: 0 14px;
                }
            }
        }
    }
}

#nextButton, #vorige-button {

    display: flex;
    align-items: center;
    gap: .5rem;
    white-space: nowrap;

    & .chevron-left, & .text-orange {
        width: auto;
    }

    & span {
        display: flex;
    }
}
