.modal-header {
    border-bottom: none;
    padding: 1.6rem;
    align-items: center;

    button {
        padding: 0;
        background-position-x: left;

        &.close {
            opacity: 1;
        }
    }

    .modal-close {
        padding: 0;
        margin: 0;
    }

    .modal-close--white {
        background-image: url(/theme/vrijopnaam/svg/close-white.svg);
    }
}

.modal-footer {
    border-top: none;
    padding: 1.6rem;
}

#investeringPon {
    .modal-dialog {

        @media (min-width: $breakpoint-small) {
            margin-left: 8px;
            margin-right: 8px;
            max-width: 739px;
        }

        @media (min-width: $breakpoint-medium) {
            margin-left: auto;
            margin-right: auto;
        }
        @media (min-width: $breakpoint-large) {
            max-width: 823px;
        }
    }

    div.modal-content.details-container {
        padding: 1rem 0.5rem 0;
        max-width: unset;

        @media (min-width: $breakpoint-medium) {
            padding: 16px;
        }

        .details {
            > div {
                &:first-child {
                    border: none;
                }
            }

            div.contractKeuze-modal.content {
                display: block;

                #zelfOpwekkenInleg {
                    margin-right: -1rem;
                }
            }
        }
    }

    button.close.modal-close.modal-close--black {
        background-image: url(/theme/vrijopnaam/svg/close_x_mark_icon.svg);
    }

    .contractKeuze-modal.header {
        display: block;
        margin: 0;
        padding-bottom: 16px;
        @media (min-width: $breakpoint-medium) {
            padding-bottom: 32px;
        }

        p {
            font-size: 18px;
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            &:first-child {
                padding-bottom: 16px;
            }
        }
    }

    .contractKeuze-modal.content {
        border-radius: 8px;
        border: solid 1px #dddede;
        background-color: var(--kleur-white);
        padding: 8px;

        @media (min-width: $breakpoint-medium) {
            padding: 24px 16px;
        }

        .header {
            border-bottom: 1px solid #dddede;
            padding-bottom: 8px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .subheader {
            margin-bottom: 24px;
        }
    }

    #zelfOpwekkenWinst {
        font-weight: bold;
    }

    div.button_container {
        margin-bottom: 8px;
    }

    button.btn.btn-orange.contractKeuze-modal {
        margin-top: 0;
        margin-bottom: 8px;
        margin-right: 16px;
        width: 100%;
        text-align: left;

        @media (min-width: $breakpoint-medium) {
            margin-top: 32px;
            margin-bottom: 0;
            width: unset;
        }
    }
}

#hoeRekenenJullie {
    .modal-dialog {
        max-width: 996px;
    }

    table {
        width: 100%;
    }

    .responsive-table {
        overflow-x: auto;
    }

    .modal-content {
        background-color: $kleur-almost-black;
        color: $kleur-white;
    }

    h2 {
        text-align: left;
        color: #ffdf10;
        font-size: 2.8rem;
        font-weight: 500;
        text-transform: uppercase;

        @media (min-width: $breakpoint-large) {
            font-size: 3.3rem;
        }
    }

    .rekenen-table {
        tr:nth-child(odd) {
            background-color: #ffdf10;
        }

        tr:nth-child(even) {
            background-color: #ffee80;
        }

        tr {
            color: #000;

            td:first-child {
                border-right: 1px solid #fff;
            }

            td {
                padding: 8px 20px;
                font-size: 1.4rem;

                @media (min-width: $breakpoint-large) {
                    font-size: 1.8rem;
                }
            }
        }
    }

    .voordeel-table {
        td {
            text-align: center;
            min-width: 100px;

            &.opbrengst {
                min-width: 205px;
            }
        }

        thead {
            tr {
                &:first-child {
                    background-color: silver;
                }

                td {
                    font-weight: 700;
                    border-right: 1px solid #fff;
                    vertical-align: bottom;
                }

                td:last-child {
                    border-right: 0;
                }
            }
        }

        tfoot {
            tr {
                &:first-child {
                    background-color: #ffee80
                }

                &:last-child {
                    background-color: #ffdf10
                }
            }

            .info,
            .total {
                font-weight: 700;
            }

            .info {
                td {
                    border-right: none;

                    &:first-child {
                        text-align: left;
                    }
                }
            }
        }

        tr:nth-child(odd) {
            background-color: #81cff4;
        }

        tr:nth-child(even) {
            background-color: #b8e1f5;
        }

        tr {
            color: #000;

            td {
                border-right: 1px solid #fff;
            }

            td:last-child {
                border-right: 0;
            }

            td {
                padding: 8px 16px;
                font-size: 1.4rem;

                @media (min-width: $breakpoint-large) {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

#verderGaan {
    .modal-dialog {
        max-width: 392px;
    }

    div.modal-content.details-container {
        padding: 1.6rem
    }

    .details {
        padding: 0;

        .header {

            .header {
                display: flex;
                flex-direction: row;
                padding-block-end: 3.2rem;
            }
        }
    }

    div.header {
        flex-direction: column;
        border: none;
        padding: 0;
        margin: 0;
    }

    .close.modal-close {
        margin-left: auto;
    }

    button {
        text-align: left;

        @media (max-width: $breakpoint-medium) {
            padding: 1rem;
            font-size: 1.5rem;
        }
    }

    div.header p {
        line-height: 1;
    }
}

#verderGaanJa {
    margin-right: 11px;
}

div#zelfopwekkenModal.modal.fade.show {
    padding: 0 !important;
}

#zelfopwekkenModal {

    div.modal-dialog.modal-dialog-centered {
        max-width: 1430px;
        margin-left: auto;
        margin-right: auto;
    }

    .modal-content {
        padding: 0.5rem;
        background-color: #fbdc2d;
    }

    .c-zelf-opwekken li {
        margin-bottom: 32px;
        padding-left: 5.6rem;
        padding-right: 4rem;
        background-repeat: no-repeat;
        background-position: left center;
        min-height: 4rem;
        display: inline-flex;
        align-items: center;
        line-height: 2.4rem;
        border: none;
        background-color: #fbdc2d;
        font-size: 1.8rem;
        font-weight: 300;
    }

    .modal-header {
        background-color: #fbdc2d;
        border-bottom: none;
        border: 0;
        padding: 0;
    }

    button.close {
        box-sizing: content-box;
        width: 2rem;
        height: 2rem;
        padding: 0;
        color: #000;
        border: 0;
        border-radius: 0.25rem;
        background-image: url(/theme/vrijopnaam/svg/cross.svg);
        margin: 0 0 0 auto;
    }


    .c-zelf-opwekken .c-zelf-opwekken__image img {
        bottom: -16px;
        top: unset;
        max-width: 180px;
    }

    section.mb-xxxl.py-l.bg--orange.c-zelf-opwekken {
        margin-bottom: 32px !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;

        p {
            display: none;
        }

        ul {
            margin-bottom: 0;
        }
    }

}

#mapOverlayModal {
    .modal-header {
        @media (max-width: 767px) {
            padding: .4rem 1.2rem .4rem .4rem;
        }
    }

    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;
        max-width: 1428px;

        .modal-content {
            height: calc(100vh - 82px);
            width: calc(100vw - 16px);
            margin: 0 auto;
            @media (min-width: $breakpoint-large) {
                height: calc(100vh - 24px);
                width: calc(100vw - 24px);
            }

            .modal-body {
                padding: 0 1px 1px 1px;

                #map {
                    width: 100% !important;
                    height: 100% !important;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                }

                .solar-park-selector-container {
                    position: absolute;
                    top: 24px;
                    left: 24px;
                    width: calc(100% - 48px);

                    select {
                        width: 100%;
                    }

                    @media (min-width: $breakpoint-medium) {
                        max-width: 228px;
                    }
                }
            }

            .mapboxgl-ctrl-attrib,
            .mapboxgl-ctrl-logo {
                display: none;
            }
        }
    }

    #RegistrationFormStep5B_zonnepark {
        width: 256px;
        background: $white;
        border: 1px solid $kleur-almost-black;
        border-radius: 6px;
        max-width: 256px;
    }
}

// Modal in Zonneparken Projecten page
.project_modal {
    .modal-dialog {
        max-width: 996px;
    }

    .modal-close {
        position: absolute;
        right: 8px;
        top: 8px;
        width: 40px;
        height: 40px;
        background-color: white;
        z-index: 2;
        background-position: center;
        border-radius: 8px;
    }

    .carousel-item {
        img {
            border-radius: 0.4rem;
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: unset;
        margin: 0 8px;
    }


    .carousel-control-prev-icon {
        transform: rotate(180deg);
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        border-radius: 8px;
        background-color: white;
        background-image: url(/theme/vrijopnaam/svg/chevron.svg);
        padding: 20px;
        background-size: auto;
    }
}

// modal mail aanbod

#new-mailoffer {

    div.input-group-append.btn-wrapper button {
        margin-bottom: 0.5rem;
        border-radius: 0.8rem;
        margin-left: -1rem;
    }
}

#mailMeModal .modal-header, .modal-body, .modal-footer {
    padding: 1rem;
}

#mailMeModal #offerteSubmitButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 1rem;
}
